import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["email", "form", "inviteWrapper"];

  static values = { url: String }

  updateInviteContainer(event) {
    // NOTE: not called, ajax-ctrl instead?
    const inviteWrapper = this.inviteWrapperTarget;
    const [_data, _status, xhr] = event.detail;
    inviteWrapper.innerHTML = '';
    inviteWrapper.innerHTML = xhr.responseText;
  }


    // handled by stimulus?
    // $('[data-patient-form]').on('ajax:success', function(event) {
      // [data, status, xhr] = event.detail;
      // $('.patient-card')[0].innerHTML = '';
      // return $('.patient-card')[0].innerHTML = xhr.responseText;


  // checkMail() {
    // // const emailTarget = this.emailTarget;
    // const formTarget = this.formTarget;
    // const patientGroup = this.patientGroupTarget;
    // fetch(this.urlValue, {
      // method: 'POST',
      // body: new FormData(formTarget),
      // headers: {
        // 'X-CSRF-Token': Rails.csrfToken()
      // },
      // credentials: 'same-origin'
    // })
    // .then(response => {
      // response.json().then(responseJSON => {
        // if (responseJSON.emailExists) {
          // if (!patientGroup.classList.contains('d-none')) {
            // patientGroup.classList.add('d-none');
          // }
        // } else {
          // if (patientGroup.classList.contains('d-none')) {
            // patientGroup.classList.remove('d-none');
          // }
        // }
      // })
    // })
    // .catch((error) => {
      // console.error('Error:', error);
    // });
  // }

  togglePatientFields() {
    const patientGroup = this.patientGroupTarget;
    // make this a target as well.
    if (document.getElementById('invite_without_account').checked) {
      patientGroup.classList.remove('d-none');
    } else {
      patientGroup.classList.add('d-none');
    }
  }
}
