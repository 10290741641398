import { Controller } from "stimulus";
import Turbolinks from "turbolinks"
// import { turbolinksReload } from '../src/turbolinks_reload';

export default class extends Controller {
  static values = { reloadTo: String }

  connect() {

    const storageItems = ['pubKey', 'privKey',
                          'passphrase', 'digestHex'];
    for (const storageItem of storageItems) {
      if (sessionStorage.getItem(storageItem)) {
        sessionStorage.removeItem(storageItem);
      }
    }
    Turbolinks.visit(this.reloadToValue,
                     { flush: true, cacheRequest: false });
  }
}
