import { Controller } from "stimulus";

export default class extends Controller {
  static values = { replaceParent: Boolean }

  connect() {
    this.element['ajaxSuccessController'] = this
  }

  formAjaxSuccess(response) {
    this.updateForm(response);
  }

  updateForm(response, ajaxReplaceElement = this.element) {
    if (this.hasReplaceParentValue) {
      ajaxReplaceElement = this.element.parentElement;
    }
    response.text().then(responseText => {
      ajaxReplaceElement.innerHTML = '';
      ajaxReplaceElement.innerHTML = responseText;
    });
  }

}
