export let canvas;

let audioContext;
let analyser;
let scriptProcessor;

export let input = null;
export let recorder = null;
// export let recording = null;
// export let isRecording = false;
export let isPlaying = false;

const waveWidth = 3;
export let canvasContext;
let peaks = [];
let width = 0;
let height = 0;
let halfHeight = 0;
let drawing = false;
let shifted = false;

export function setCanvas(canvasElement) {
  canvas = canvasElement;
}

export function setCanvasContext(canvasContextObj) {
  canvasContext = canvasContextObj;
}

function processInput(audioProcessingEvent) {
  const array = new Uint8Array(analyser.frequencyBinCount);

  analyser.getByteFrequencyData(array);
  peaks.push(getAverageVolume(array.slice(0, array.length / 2)));

  if (peaks.length <= Math.floor(width / waveWidth)) {
    renderWaves(peaks);
  } else {
    renderWaves(
      peaks.slice(peaks.length - Math.floor(width / waveWidth)),
      peaks.length
    );
  }
}

function getAverageVolume(array) {
  const length = array.length;

  let values = 0;

  for (let i = 0; i < length; i++) {
    values += array[i];
  }

  return values / length;
}

function renderWaves() {
  if (!drawing) {
    drawing = true;

    window.requestAnimationFrame(() => {
      canvasContext.clearRect(0, 0, width, height);
      const end = peaks.length * waveWidth;
      const start = 0;

      canvasContext.beginPath();
      canvasContext.moveTo(0, halfHeight);

      peaks.forEach((peak, index) => {
        const isEven = (index + 1) % 2;

        if ((isEven && !shifted) || (!isEven && shifted)) {
          canvasContext.lineTo(
            index * waveWidth,
            halfHeight - halfHeight * (peak / 100)
          );
        } else {
          canvasContext.lineTo(
            index * waveWidth,
            halfHeight + halfHeight * (peak / 100)
          );
        }
      });
      if (end >= width) {
        let diff = end - width;
        diff++;
        for (let i = 0; i < diff; i++) {
          peaks.shift();
          shifted = !shifted;
        }
      }
      canvasContext.stroke();
      canvasContext.moveTo(end, halfHeight);

      drawing = false;
    });
  }
}

export function setupStream(audioStream) {
  // console.log('aStr', audioStream);
  audioContext = new AudioContext();
  input = audioContext.createMediaStreamSource(audioStream);
  analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0;
  analyser.fftSize = 32;
  scriptProcessor = audioContext.createScriptProcessor(1024, 1, 1);
  input.connect(analyser);
  analyser.connect(scriptProcessor);
  scriptProcessor.connect(audioContext.destination);
  scriptProcessor.onaudioprocess = (audioProcessingEvent) => {
    processInput(audioProcessingEvent);
  };
}

export function teardown() {
  if (recorder.state !== "inactive") recorder.stop();
  input.disconnect();
  analyser.disconnect();
  scriptProcessor.disconnect();
  audioContext.close();
}

export function setupRecorder(mediaStream) {
  recorder = mediaStream;
}

export function setupCanvas() {
  canvasContext = canvas.getContext("2d");
  width = canvas.offsetWidth;
  height = canvas.offsetHeight;
  halfHeight = canvas.offsetHeight / 2;

  canvasContext.canvas.width = width;
  canvasContext.canvas.height = height;
}
