export function spinnerShow() {
  const spinner = document.querySelector("#e2ee-spinner");
  if (spinner && spinnerHidden(spinner)) {
    spinner.classList.add('text-primary');
    spinner.classList.remove('text-light');
  }
}

export function spinnerHide() {
  const spinner = document.querySelector("#e2ee-spinner");
  if (spinnerVisible(spinner)) {
    if (document.querySelectorAll("[data-crypt-in-progress=true]").length === 0) {
      spinner.classList.remove('text-primary');
      spinner.classList.add('text-light');
    }
  }
}

function spinnerVisible(spinner) {
  return spinner?.classList?.contains('text-primary');
}

function spinnerHidden() {
  return !spinnerVisible();
}
