export async function loadPGP() {
  await import("../lib/openpgp");
}

export async function generateKey(IdData, passphrase) {
  await loadPGP();
  return await openpgp.generateKey({
    curve: "curve25519",
    userIds: IdData,
    passphrase: passphrase,
  });
}

export async function registerKey(privateKeyArmored, publicKeyArmored, passphrase) {
  await loadPGP();
  const plainPublicKey = publicKeyArmored;
  const plainPrivateKey = privateKeyArmored;
  const { keys: [privKeyObj] } = (await openpgp.key.readArmored(plainPrivateKey))
  if (await privKeyObj.decrypt(passphrase)) {
    sessionStorage.setItem("pubKey", JSON.stringify(plainPublicKey));
    sessionStorage.setItem("privKey", JSON.stringify(plainPrivateKey));
    sessionStorage.setItem("passphrase", btoa(passphrase));
  }
}

export async function basicDigestMessage(message) {
  // grabbed straight from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

export async function readArmoredKey(armoredKey) {
  return (await openpgp.key.readArmored(armoredKey))
}

export async function unlockedKeyPresent() {
  await loadPGP();
  if(unlockedKeyPresent.cache) {
    return unlockedKeyPresent.cache;
  }

  // try it a few times before giving up.
  // seems like sessionStorage is not always reliably available that early during page loading
  let iterations = 0;
  let sessionStorageInterval = setInterval(function() {
    if (iterations++ >= 9) clearInterval(sessionStorageInterval);
    if (sessionStorage.getItem("passphrase") === null) return;
    clearInterval(sessionStorageInterval);
  }, 50);

  const sessionStoragePassphrase = sessionStorage.getItem("passphrase");
  if (sessionStoragePassphrase === null || atob(sessionStoragePassphrase) === undefined) {
    return false
  }
  const privateKey = getPrivateKey();
  const passphrase = getPassphrase();
  const { keys: [privKeyObj] } = (await openpgp.key.readArmored(privateKey));
  unlockedKeyPresent.cache = privKeyObj && await privKeyObj.decrypt(passphrase);
  return unlockedKeyPresent.cache;
}

export async function decryptText(armoredMessage) {
  await loadPGP();

  if (!unlockedKeyPresent()) {
    return;
  }

  if(!decryptText.cache) {
    const privateKey = getPrivateKey();
    const passphrase = getPassphrase();
    const { keys: [privKeyObj] } = (await openpgp.key.readArmored(privateKey))
    await privKeyObj.decrypt(passphrase);
    decryptText.cache = privKeyObj;
  }
  const message = await openpgp.message.readArmored(armoredMessage);
  const options = {
    message: message,
    privateKeys: [decryptText.cache]
  };

  const { data: decrypted } = await openpgp.decrypt(options);
  return decrypted;
}

export function getPrivateKey() {
  if(getPrivateKey.cache) {
    return getPrivateKey.cache;
  }
  getPrivateKey.cache = JSON.parse(sessionStorage.getItem("privKey"));
  return getPrivateKey.cache;
}

export function getPassphrase() {
  if(getPassphrase.cache) {
    return getPassphrase.cache;
  }
  getPassphrase.cache =  atob(sessionStorage.getItem("passphrase"));
  return getPassphrase.cache
}

export async function encryptText(text, keys) {
  await loadPGP();
  const message = openpgp.message.fromText(text);
  const { data: encrypted } = await openpgp.encrypt({
    message,
    publicKeys: keys,
  });
  return encrypted;
}

export async function encryptFile(file, keys) {
  await loadPGP();
  // const message = await openpgp.createMessage({ binary: file.stream() });
  const message = await openpgp.message.fromBinary(file.stream());
  const { data: encrypted } = await openpgp.encrypt({
    message,
    publicKeys: keys,
    // armor: false, // seems broken
  });


  const reader = openpgp.stream.getReader(encrypted);
  let encryptedFileData = []
  while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      encryptedFileData.push(value)
  }

  return new File(encryptedFileData,
                  file.name,
                  { type: file.type,
                    lastModified: file.lastModified })
}

export async function decryptFile(file, filename, fileType) {
  await loadPGP();

  if (!unlockedKeyPresent()) {
    return;
  }

  if(!decryptText.cache) {
    const privateKey = getPrivateKey();
    const passphrase = getPassphrase();
    const { keys: [privKeyObj] } = (await openpgp.key.readArmored(privateKey))
    await privKeyObj.decrypt(passphrase);
    decryptText.cache = privKeyObj;
  }
  // const message = await openpgp.message.fromBinary(file.stream());
  const message = await openpgp.message.readArmored(file);
  const options = {
    message: message,
    format: 'binary',
    privateKeys: [decryptText.cache]
  };

  const { data: decrypted } = await openpgp.decrypt(options);


  const reader = openpgp.stream.getReader(decrypted);
  let decryptedFileData = []
  while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      decryptedFileData.push(value)
  }

  return new File(decryptedFileData,
                  filename,
                  { type: fileType })
}

export async function readArmoredPublicKeys(publicKeys) {
  // console.log(publicKeys)
  let publicKeysPromises = publicKeys.map(async (key) => {
        let prm =  (await openpgp.key.readArmored(key)).keys[0]
        // console.log(prm);
        return prm;
      });
  // console.log(publicKeysPromises);
  return publicKeysPromises;
}
