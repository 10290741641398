import { Controller } from "stimulus";
import * as bootstrap from 'bootstrap';
import { unlockedKeyPresent } from "../model/crypto";
import Turbolinks from "turbolinks"
import jQuery from 'jquery';

export default class extends Controller {
static targets = ["locked", "unlocked"];

  async connect() {
    // const contentTarget = this.contentTarget
    // $(document).on("keys_popover_controller.state", function (event, callback) {
        // callback(this);
      // }.bind(this)
    // );
    // document.addEventListener("turbolinks:load", function(_event) {
    await this.initializePopovers();
    // });
  }

  async initializePopovers() {
    const lockedTarget = this.lockedTarget;
    const unlockedTarget = this.unlockedTarget;
    const blocker = document.getElementById('e2ee-blocker-container');
    if (await unlockedKeyPresent()) {
      unlockedTarget.classList.remove('d-none');
      lockedTarget.classList.add('d-none');

      var popover = new bootstrap.Popover(unlockedTarget, {
        html: true,
        placement: 'left',
        container: 'body',
        sanitize: false,
        // offset: 50,
        content: function () {
          return $("#keys-unlocked-popover-content").html();
        },
      })
    }
    else if (blocker) {
      var popover = new bootstrap.Popover(lockedTarget, {
        html: true,
        placement: 'left',
        container: 'body',
        sanitize: false,
        // offset: 50,
        content: function () {
          return $("#keys-popover-content").html();
        },
      });
    } else {
      Turbolinks.visit('/e2ee_unlock', { flush: true, cacheRequest: false })
    }
    this.popoverReady = true;
  }

  showInformation(_event) {
    this.pollInformation(0);
  }

  pollInformation(iter = 0) {
    if (document.querySelectorAll("[data-crypt-in-progress]").length !== 0 &&
        document.querySelectorAll("[data-crypt-in-progress=true]").length === 0) {
      this.parseInformation();
    } else {
      if (iter++ >= 10) return;
        setTimeout(() => { this.pollInformation(iter) }, 200);
    }
  }

 parseInformation() {
    const userKeysInfoElement = document.getElementById('userKeysInfo');
    if (!userKeysInfoElement) return;

    const arr = Array.from(document.querySelectorAll('[data-e2ee-pgp-id]'));
    if (arr.length === 0) return;

    const keyInfo = arr.map(entry => {
        // let obb = {}; \
        const keyUserArray = Array.from(entry.children);
        const keyUserNames = keyUserArray.map( userChild => {
          return userChild.textContent;
        } )
        const keyUserName = keyUserNames.join(' ');
        // obb[entr.dataset['e2eePgpId']] = key_user_name;
        // return obb;
        return [keyUserName, entry.dataset['e2eePgpId']];
    });
    // const uniqueKeyInfo = new Set(keyInfo);
    userKeysInfoElement.textContent = '';
    // var ttlist;
    // uniqueKeyInfo.forEach((keyInfos, _redundant, _set) => {
    const processedKeyIds = [];
    keyInfo.forEach((keyInfos, _redundant, _set) => {
      // info_child.innerHTML = `${key_infos[0]}: ${key_infos[1]}`;
      const keyId = keyInfos[1];
      if (processedKeyIds.includes(keyId)) return;
      const infoChild = document.createElement('p') ;
      infoChild.textContent = keyInfos[0];

      infoChild.dataset.bsToggle = 'tooltip';
      // infoChild.dataset.bsPlacement = 'top';
      // infoChild.dataset.bsContainer = 'body';
      // infoChild.dataset.bsTrigger = 'click';
      infoChild.dataset.bsTitle = keyId;
      infoChild.classList.add('key-info-tooltip');
      // infoChild.setAttribute('title', keyInfos[1]);
      userKeysInfoElement.appendChild(infoChild);
      // this.ttlist = new bootstrap.Tooltip(infoChild);
      processedKeyIds.push(keyId);
    })
    this.initializeTooltips();
  }

  initializeTooltips() {
    new bootstrap.Tooltip(document.body, {
      selector: '.key-info-tooltip',
      placement: 'top',
      container: 'body',
      trigger: 'click',
    });
  }
}
