import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.element['ajaxSuccessController'] = this
  }

  formAjaxSuccess(response) {
    this.updateForm(response);
  }

  updateForm(response, ajaxReplaceElement = this.element.parentElement) {
    // TODO: make new form appear on top of other new forms.
    // aaaaand rewrite this in general. it's odd
    response.text().then(responseText => {
      ajaxReplaceElement.innerHTML = '';
      ajaxReplaceElement.append = responseText;
    });
  }
}
