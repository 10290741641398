import { Controller } from "stimulus";
import Swal from 'sweetalert2';

export default class extends Controller {
  async connect() {
    // we're (probably) dealing with Chrome
    if (navigator.userAgent.indexOf("Chrome") !== -1) return;
    // already shown on this page load
    if (this.hasSeenBrowserWarning) return;
    // only show once per session, if possible
    if (this.browserWarningShown()) return;
    this.showBrowserWarning();
  }

  browserWarningShown() {
    // try it a few times before giving up.
    // seems like sessionStorage is not always reliably available that early during page loading
    let iterations = 0;
    let sessionStorageInterval = setInterval(function() {
      if (iterations++ >= 9) clearInterval(sessionStorageInterval);
      if (sessionStorage.getItem("browserWarningShown") === null) return;
      clearInterval(sessionStorageInterval);
    }, 50);

    const shown = sessionStorage.getItem("browserWarningShown");
    return (shown !== null);
  }

  showBrowserWarning() {
    Swal.fire({
      title: 'Inkompatibler Browser',
      icon: 'info',
      text:
        'Bitte benutzen Sie für die Verwendung der Plattform ' +
        'den Chrome-Browser. Mit Ihrem aktuellen Browser ' +
        'können wir im Moment nicht für eine reibungslose ' +
        'Funktion der Plattform garantieren.',
      focusConfirm: true,
      confirmButtonAriaLabel: 'OK',
    });
    this.hasSeenBrowserWarning = true;
    sessionStorage.setItem("browserWarningShown", 'true');
  }
}
