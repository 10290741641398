import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["endSessionButton", "deviceDisconnectButton"];
  static values = {
    consultationId: String,
    roomName: String,
    currentUser: String
  }

  endSession() {
    const consultationId = this.consultationIdValue;
    // async?
    return $.ajax({
      type: "POST",
      url: "/consultations/" + consultationId,
      data: {
        id: consultationId,
        state: 'closed',
        _method: 'put',
        is_ajax: 'true'
      },
        success: (_data) => {
        $(this.endSessionButtonTarget).hide();
        this.broadcastData({
          type: 'END_SESSION',
          from: this.currentUserValue,
          consultation: consultationId,
          roomName: this.roomNameValue
        });
        return true;
      },
      error: function(data) {
        return false;
      }
    });
  }
   addTreatment(){
    const consultationId = this.consultationIdValue;
    // for now; convert to value at some point
    var nextTreatment;
    // nextTreatment = parseInt($('.treatment-subform').last().children().first().val()) + 1;
    nextTreatment = parseInt($('.treatment-subform').first().children().first().val()) + 1;
    return $.ajax({
      type: "POST",
      url: "/consultations/" + consultationId + "/treatments/new",
      data: {
        consultation_id: consultationId,
        next_treatment: nextTreatment
      },
      success: function(data) {
        // $(data).insertAfter($('.treatment-subform').last());
        $(data).insertBefore($('.treatment-subform').first());
        return true;
      },
      error: function(data) {
        return false;
      }
    });
  }

   addDiagnosis(){
    const consultationId = this.consultationIdValue;
    // for now; convert to value at some point
    var nextDiagnosis;
    // nextDiagnosis = parseInt($('.diagnosis-subform').last().children().first().val()) + 1;
    nextDiagnosis = parseInt($('.diagnosis-subform').first().children().first().val()) + 1;
    return $.ajax({
      type: "POST",
      url: "/consultations/" + consultationId + "/diagnoses/new",
      data: {
        consultation_id: consultationId,
        next_diagnosis: nextDiagnosis
      },
      success: function(data) {
        // $(data).insertAfter($('.diagnosis-subform').last());
        $(data).insertBefore($('.diagnosis-subform').first());
        return true;
      },
      error: function(data) {
        return false;
      }
    });
  }

  findingPre() {
    var data, status, xhr;
    [data, status, xhr] = event.detail;
    // TODO: make new form appear on top of other new forms.
    // aaaaand rewrite this in general. it's odd
    $(event.target).after(xhr.responseText);
  }

  broadcastData(data) {
      $.ajax({url: "/sessions", type: "post", data});
  }

  disconnectDevice() {
    const deviceDisconnectButton = deviceDisconnectButtonTarget;
    const deviceId = deviceDisconnectButton.dataset['deviceId'];
      this.broadcastData({
        type: 'REMOVE_DEVICE',
        from: this.currentUserValue,
        deviceId: deviceId,
        roomName: this.roomNameValue
      });
    deviceDisconnectButton.dataset['deviceId'] = '';
    deviceDisconnectButton.classlist.add('d-none')
  }
}
