import { Controller } from "stimulus";
import * as WebRTC from "../model/webrtc";
import Rails from "@rails/ujs";
import * as bootstrap from 'bootstrap';
import adapter from 'webrtc-adapter';
import { spinnerShow, spinnerHide } from "../model/spinner";
import { unlockedKeyPresent, encryptFile, readArmoredPublicKeys } from "../model/crypto";

export default class extends Controller {
  static values = { currentUserId: String,
                    roomName: String,
                    isDoctor: Boolean,
                    recordingsAllowed: Boolean,
                    consultationId: String,
                    remoteUser: String }
  static targets = [ "selfView",
                     "remoteViewContainer",
                     "remoteDeviceViewContainer",
                     "remoteAudioViewContainer",
                     "localViewContainer",
                     "placeholderVideoRemote",
                     "remoteDeviceViewContainer",
                     "outputSelector",
                     "deviceSelector",
                     "deviceDisconnectButton",
                     "remoteView",
                     "remoteDeviceView",
                     "publicKeyId",
                     "captureImageOutput",
                     "captureCanvas",
                     "captureModal",
                     "uploadCaptureButton",
                     "downloadCapture",
                     "captureContainer" ]

  connect() {
    if (WebRTC.acceptedId !== undefined || WebRTC.proposedId !== undefined) return;
    WebRTC.requestIceCandidates(this.roomNameValue)
      .then(WebRTC.initializeLocalStream(this.selfViewTarget,
                                         this.outputSelectorTarget))
      .then(WebRTC.handleJoinSession(this.currentUserIdValue,
                                     this.remoteViewContainerTarget));
    this.cleanedUp = false;
  }

  removeDevice(event) {
    const deviceId = event.target.dataset['deviceId'];
    let deviceIds = new Array();
    deviceIds.push(deviceId)
    WebRTC.broadcastData({ type: WebRTC.REMOVE_DEVICE,
                           from: this.currentUserIdValue,
                           deviceId: deviceId,
                           roomName: this.roomNameValue });

    WebRTC.deviceCleanup(deviceIds);
    WebRTC.removeDeviceButton(deviceId);
  }

  // devRemoveAudioTrack() {
    // console.log('removing');
    // let localStream = WebRTC.localStream;
    // let mainId = WebRTC.mainAudioTrackId;
    // let wrtc = WebRTC;
    // const origTrack = WebRTC.localStream.getTrackById(WebRTC.mainAudioTrackId)
    // let origSender;
    // const senderList = WebRTC.mainPc.getSenders();
    // senderList.forEach((sender) => {
      // if (sender.track === origTrack) {
        // console.log('origSender: ', sender);
        // origSender = sender;
        // }
    // })
    // origTrack.stop();
    // // WebRTC.localStream.removeTrack(WebRTC.localStream.getTrackById(WebRTC.mainAudioTrackId))
    // localStream.removeTrack(origTrack);
    // // WebRTC.mainPc.removeTrack(origTrack);
    // const constraints = {
      // audio: true,
      // video: true,
    // };
    // navigator.mediaDevices
      // .getUserMedia(constraints)
      // .then((stream) => {
        // stream.getAudioTracks().forEach ( (track) => {
          // localStream.addTrack(track);
          // // WebRTC.mainPc.addTrack(track, localStream);
          // origSender.replaceTrack(track);
        // } )
    // })
    // console.log('mainPc', WebRTC.mainPc);
    // console.log('localStream', WebRTC.localStream);
  // }

  cleanupBeforeReload() {
    console.log('beforeReloadCalled');
    this.teardown();
  }

  requestRecordingPermission() {
    WebRTC.broadcastData({ type: WebRTC.REQUEST_RECORDING_PERMISSION,
                           from: this.currentUserIdValue,
                           roomName: this.roomNameValue });
  }

  capture() {
    if (!this.hasRemoteViewTarget && !this.hasRemoteDeviceViewTarget) return;

    if (!this.recordingsAllowedValue) {
      this.requestRecordingPermission();
      return;
    }

    // NOTE: fall back to default view if device view is not available
    const remoteView = this.hasRemoteDeviceViewTarget ? this.remoteDeviceViewTarget : this.remoteViewTarget;
    // const height = remoteView.videoHeight;
    // const width = remoteView.videoWidth;
    const width = 1024;
    const height = remoteView.videoHeight / (remoteView.videoWidth/width);

    const canvas = this.captureCanvasTarget;
    const context = canvas.getContext('2d');

    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    context.drawImage(remoteView, 0, 0, width, height);

    canvas.toBlob((blob) => {
      this.capturedBlob = blob;
    })

    canvas.toBlob((blob) => {
      const newImg = document.createElement('img'),
        url = URL.createObjectURL(blob);


      newImg.onload = () => {
        URL.revokeObjectURL(url);
      };

      newImg.src = url;
      newImg.classList.add('img-fluid');
      newImg.classList.add('mx-auto');
      newImg.classList.add('d-block');
      this.captureImageOutputTarget.appendChild(newImg);
    })

    this.downloadCaptureTarget.href = canvas.toDataURL();

    const captureModalElement = this.captureModalTarget;
    const captureModal = new bootstrap.Modal(captureModalElement);
    captureModal.show();
  }

  dismissCaptureModal() {
    const captureModalElement = this.captureModalTarget;
    const captureModal = bootstrap.Modal.getInstance(captureModalElement);
    captureModal.hide();
    this.capturedBlob = null;
  }

  captureModalDismissed() {
    this.captureImageOutputTarget.firstElementChild?.remove();
    if (this.hasDownloadCaptureTarget) this.downloadCaptureTarget.href = '';
  }

  async uploadCapture() {
    this.element.dataset.cryptInProgress = true;
    const consultationId = this.consultationIdValue;
    const roomName = this.roomNameValue;
    const currentUserId = this.currentUserIdValue;
    const uploadUrl = `/consultations/${consultationId}`;
    spinnerShow();
    const publicKeyIdTargets = this.publicKeyIdTargets;
    const publicKeyIds = publicKeyIdTargets.map( function(publicKeyId) { return publicKeyId.textContent.trim(); } );
    this.uploadCaptureButtonTarget.setAttribute("disabled", "disabled");
    const publicKeys = await this.getPublicKeys(publicKeyIds);
    const publicKeyObjs = await readArmoredPublicKeys(publicKeys);
    const publicKeyObjsResolved = await Promise.all(publicKeyObjs);
    const datestring = new Date().toISOString();
    const filename = `screenshot_${datestring}.png`
    const plaintextFile = new File([this.capturedBlob],
                                   filename,
                                   { type: 'image/png',
                                     lastModified: new Date().getTime() });

    const encrypted = await encryptFile(plaintextFile, publicKeyObjsResolved)

    const formData = new FormData();
    formData.append('consultation[consultation_id]', consultationId);
    formData.append('consultation[consultation_upload]', encrypted, filename)
    try {
      fetch(uploadUrl, {
        method: 'PUT',
        body: formData,
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        console.log('bc_data: ', {
          type: 'REFRESH_UPLOADS',
          from: currentUserId,
          roomName: roomName,
          consultationId: consultationId,
        });
        this.broadcastData({
          type: 'REFRESH_UPLOADS',
          from: currentUserId,
          roomName: roomName,
          consultationId: consultationId,
        });
      });
    }
    catch (err) {
      console.error(err);
    }
    finally {
      this.uploadCaptureButtonTarget.removeAttribute('disabled');
      this.element.dataset.cryptInProgress = false;
      spinnerHide();
      this.dismissCaptureModal();
    }
  }

  async getPublicKeys(publicKeyIds) {
    const publicKeysUrlValue = `/users/${this.currentUserIdValue}/e2ee_public_keys`
    const response = await fetch(publicKeysUrlValue, {
      method: 'post',
      body: JSON.stringify(publicKeyIds),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });

    return response.json();
  }

  disconnect() {
    console.log('disconnect()');
    this.teardown();
  }

  teardown() {
    if (this.cleanedUp) return;
    this.cleanedUp = true;
    console.log('beforeCache called');
    // if (WebRTC.acceptedId !== undefined)
    // {
    WebRTC.handleLeaveSession(false);
    // }
    // WebRTC.setLocalUserJoined(false);
  }

  async broadcastData(data) {
    let formData = new FormData();
    for (const [k, v] of Object.entries(data)) {
      formData.append(k, v);
    }

    fetch('/sessions', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: formData,
        credentials: 'same-origin'
    })
    .then(response => {
      response.text().then(responseText => {
        console.log(responseText);
      })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

}
