import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static values = {
    previewUrl: String,
    consultationId: String,
    roomName: String,
    currentUserId: String,
    replaceParent: Boolean,
  };

  static targets = ["letterModal", "letterContainer"];

  connect() {
    this.element["ajaxSuccessController"] = this;
  }

  formAjaxSuccess(response) {
    this.updateForm(response);
  }

  updateForm(response, ajaxReplaceElement = this.element) {
    if (this.hasReplaceParentValue) {
      ajaxReplaceElement = this.element.parentElement;
    }
    // console.log('elem', ajaxReplaceElement)
    response
      .text()
      .then((responseText) => {
        ajaxReplaceElement.innerHTML = "";
        ajaxReplaceElement.innerHTML = responseText;
      })
      .then(() => this.broadcastLetter());
  }

  previewLetter() {
    const previewUrl = this.previewUrlValue;
    const letterContainer = this.letterContainerTarget;
    const consultationId = this.consultationIdValue;
    let formData = new FormData();
    formData.append("consultation_id", consultationId);
    // toggle modal after ajax?
    fetch(previewUrl, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: formData,
      credentials: "same-origin",
    })
      .then((response) => {
        response
          .text()
          .then((responseText) => {
            letterContainer.innerHTML = "";
            letterContainer.innerHTML = responseText;
          })
          .then(() => this.showModal());
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  showModal() {
    const letterModalElement = this.letterModalTarget;
    const letterModal = new bootstrap.Modal(letterModalElement);
    letterModal.show();
  }

  dismissModal() {
    const letterModalElement = this.letterModalTarget;
    const letterModal = bootstrap.Modal.getInstance(letterModalElement);
    letterModal.hide();
  }

  broadcastLetter() {
    this.broadcastData({
      type: "LETTER",
      from: this.currentUserIdValue,
      consultation: this.consultationIdValue,
      roomName: this.roomNameValue,
    });
  }

  broadcastData(data) {
    $.ajax({ url: "/sessions", type: "post", data });
  }
}
