import { Controller } from "stimulus";

export default class extends Controller {

static values = { signIn: Boolean }

async connect() {
  if (this.signInValue) this.cleanSessionStorage();
}

async cleanSessionStorage() {
    ['privKey',
    'passphrase',
    'pubKey'].forEach(sym => {
      sessionStorage.removeItem(sym);
    });
  }
}
