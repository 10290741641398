import { Controller } from "stimulus";
import { unlockedKeyPresent } from "../model/crypto";
import * as bootstrap from 'bootstrap';
// import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["information", "unlock", "spinner"]

  static values = { returnPath: String,
                    keyPresent: Boolean }

  connect() {
    // var keysPopoverCtrl;
    // $(document).trigger('keys_popover_controller.state', function(keysPopoverController) {
      // keysPopoverCtrl = keysPopoverController;
      // // pollPopover(keysPopoverController);
    // });
    //
    // this.pollPopover(keysPopoverCtrl);
    // TODO: we should try this again with callbacks (on popover ready or something like that?)
    setTimeout(() => { this.showPopover(); }, 1000);
  }

  pollPopover(keysPopoverController) {
    if (keysPopoverController.popoverReady) {
      this.showPopover();
    } else {
      setTimeout(this.pollPopover(keysPopoverController), 500);
    }
  }

  showPopover() {
    const informationTarget = this.informationTarget;
    const unlockTarget = this.unlockTarget;
    const spinnerTarget = this.spinnerTarget;
    spinnerTarget.classList.add('d-none');
    if (this.keyPresentValue) {
      unlockTarget.classList.remove('d-none');
    } else {
      informationTarget.classList.remove('d-none');
    }
    const keysPopover = document.getElementById('keys-popover');
    const popover = bootstrap.Popover.getInstance(keysPopover)
    popover?.show();
  }
}
