import { Controller } from "stimulus";
// import { generateKey, registerKey } from "../model/crypto";
import { unlockedKeyPresent, decryptText, encryptText, readArmoredPublicKeys } from "../model/crypto";
import Rails from "@rails/ujs";
import { turbolinksReload } from '../src/turbolinks_reload';
import { spinnerShow, spinnerHide } from "../model/spinner";
import { BroadcastChannel } from 'broadcast-channel';

export default class extends Controller {
  static targets = ["ciphertextField", "plaintextField",
                    "publicKeyId"];
  static values = { url: String,
                    redirectUrl: String,
                    publicKeysUrl: String,
                    method: String,
                    decryptionError: String,
                    checkBroadcastChannel: Boolean }

  async connect() {
    const ciphertextFieldTargets = this.ciphertextFieldTargets;
    const plaintextFieldTargets = this.plaintextFieldTargets;
    const decryptionError = this.decryptionErrorValue;
    this.element.dataset.cryptInProgress = true;
    spinnerShow();
    if (this.hasCheckBroadcastChannelValue && this.checkBroadcastChannelValue) {
      await this.credsFromBroadcastChannel();
    }

    if (await unlockedKeyPresent()) {
      ciphertextFieldTargets.forEach( function(cipherFieldTarget, idx) {
        const armoredMessage = cipherFieldTarget.value;
        if (armoredMessage.length === 0) return;
        try {
          decryptText(armoredMessage).then( function(decrypted) {
            plaintextFieldTargets[idx].value = decrypted;
          });
        } catch (pgpErr) {
            plaintextFieldTargets[idx].value = decryptionError;
        }
      })
    }
    this.element.dataset.cryptInProgress = false;
    spinnerHide();
  }

  async encryptFieldsFromBlur(event) {
    for (const plaintextFieldTarget of this.plaintextFieldTargets) {
      if (plaintextFieldTarget.dataset?.action?.includes('blur->')) {
        delete plaintextFieldTarget.dataset.action
      }
    }
    this.encryptFields(event);
  }

  async encryptFields(event) {
    // normal ajax submit if no ciphertext to process, eg. doctor's profile
    if (!this.hasPlaintextFieldTarget) {
      event.preventDefault();
      event.stopPropagation();
      await this.submitForm();
      return;
    }
    this.element.dataset.cryptInProgress = true;
    spinnerShow();
    event.preventDefault();
    event.stopPropagation();
    if (this.plaintextFieldTargets.every((element) => !element.value || !element.value.trim())) {
      this.element.dataset.cryptInProgress = false;
      spinnerHide();
      return;
    }
    // if (event.type === 'blur' || event.type === 'focusout') {
      // console.log('rm blur:', event.target);
      // delete event.target.dataset.action
    // }
    const ciphertextFieldTargets = this.ciphertextFieldTargets;
    const plaintextFieldTargets = this.plaintextFieldTargets;
    const publicKeyIdTargets = this.publicKeyIdTargets;
    const publicKeyIds = publicKeyIdTargets.map( function(publicKeyId) { return publicKeyId.textContent.trim(); } );
    const publicKeys = await this.getPublicKeys(publicKeyIds);
    const publicKeyObjs = await readArmoredPublicKeys(publicKeys);
    const publicKeyObjsResolved = await Promise.all(publicKeyObjs);
    let idx = 0;
    for (const plaintextFieldTarget of plaintextFieldTargets) {
    // await plaintextFieldTargets.forEach( async function(plaintextFieldTarget, idx) {
      const plaintextMessage = plaintextFieldTarget.value;
      await encryptText(plaintextMessage, publicKeyObjsResolved).then(async function(encrypted) {
        ciphertextFieldTargets[idx++].value = encrypted;
      });
    }
    this.element.dataset.cryptInProgress = false;
    spinnerHide();
    await this.submitForm();
    // await this.pollCiphertext(0);
    }

  async getPublicKeys(publicKeyIds) {
    // let publicKeys = [];
    // Rails.ajax({
      // type: "post",
      // url: this.publicKeysUrlValue,
      // beforeSend(xhr, options) {
        // xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // options.data = JSON.stringify(publicKeyIds)
        // return true
      // },
      // // data: { publicKeyIds: publicKeyIds },
      // returnData: publicKeys,
      // success: function(data) {
        // this.returnData = data.publicKeys;
      // }
    // })
    // return publicKeys;
    const response = await fetch(this.publicKeysUrlValue, {
      method: 'post',
      body: JSON.stringify(publicKeyIds),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });

    return response.json();
  }

  // async pollCiphertext(iter) {
    // console.log('polling', this.ciphertextFieldTarget.value.length)
    // if (this.ciphertextFieldTarget.value.length === 0) {
      // if (iter++ >= 10) return;
      // setTimeout(await this.pollCiphertext(iter), 500);
    // } else {
      // this.submitForm();
    // }
  // }

  async submitForm() {
    const formTarget = this.hasFormElementTarget ? this.formElementTarget : this.element
    // const frm = new FormData(formTarget);
    // for (const entry of frm.entries())
    // {
        // console.log(entry);
    // }
    fetch(this.urlValue, {
      method: this.hasMethodValue ? this.methodValue : 'POST',
      body: new FormData(formTarget),
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => {
      if (this.element.ajaxSuccessController) {
        this.element.ajaxSuccessController.formAjaxSuccess(response);
      } else {
        // for now just reload. instead maybe redir? => or handle in formAjaxSuccess.
        // also: handle error display in places where the controllers currently return a full page, for example
        if (this.hasRedirectUrlValue) {
          Turbolinks.visit(this.redirectUrlValue,
                           { flush: true, cacheRequest: false });
        } else {
          turbolinksReload();
        }
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

   async credsFromBroadcastChannel() {
    const keyChannel = new BroadcastChannel('tabExchange');
    let msgAnswered = false;
    keyChannel.onmessage = msg => {
      if (msg.answer) {
        sessionStorage.setItem("pubKey", msg.pubKey);
        sessionStorage.setItem("privKey", msg.privKey);
        sessionStorage.setItem("passphrase", msg.passphrase);
        msgAnswered = true;
      }
    };
    keyChannel.postMessage({ request: true })

    let iterations = 0;
    let sessionStorageInterval = setInterval(function() {
      if (iterations++ >= 9) clearInterval(sessionStorageInterval);
      if (sessionStorage.getItem("passphrase") === null) return;
      clearInterval(sessionStorageInterval);
    }, 50);
    return msgAnswered;
    }

  teardown() {
    const plaintextFieldTargets = this.plaintextFieldTargets;
    plaintextFieldTargets.forEach( function(plaintextFieldTarget) {
      plaintextFieldTarget.value = '…';
    })
  }
}
