import { Controller } from "stimulus";
  // TODO: later
  // static targets = ["patientCard"];

export default class extends Controller {

  connect() {
    this.element['ajaxSuccessController'] = this
  }

  formAjaxSuccess(response) {
    this.patientCards(response);
  }

  patientCards(response) {
  response.text().then(responseText => {
      $('.patient-card')[0].innerHTML = '';
      $('.patient-card')[0].innerHTML = responseText;
    })
  }
}
