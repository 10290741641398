import { Controller } from "stimulus";
import { registerKey } from "../model/crypto";
import Rails from "@rails/ujs";
import { turbolinksReload } from '../src/turbolinks_reload';

export default class extends Controller {
  static targets = ["userHashid", "password",
                    "unlockForm", "submitButton",
                    "toggleVisible", "toggleInvisible"];

  static values = { passwordLengthMessage: String,
                    url: String }

  validateForm(event) {
    const form = this.unlockFormTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
  }

  validatePasswords() {
    // const password = this.passwordTarget.value.trim();
    return;
  }

  async register(key, passphrase) {
    if (key) {
      await registerKey(key.privateKeyArmored, key.publicKeyArmored, passphrase);
    }
  }

  toggleVisibility() {
    const password = this.passwordTarget;
    const toggleVisible = this.toggleVisibleTarget;
    const toggleInvisible = this.toggleInvisibleTarget;
    if (password.type === 'password') {
      password.type = 'text'
      toggleVisible.classList.add('d-none')
      toggleInvisible.classList.remove('d-none')
    } else {
      password.type = 'password'
      toggleInvisible.classList.add('d-none')
      toggleVisible.classList.remove('d-none')
    }
  }

  async getKeys(event) {
    event.preventDefault();
    const passphrase = this.passwordTarget.value.trim();
    Rails.ajax({
      type: 'GET',
      url: this.urlValue,
      successFunction: this.unlockKeys,
      unlockPassphrase: passphrase,
      success: function(data) {
        this.successFunction(data.privateKey, data.publicKey, this.unlockPassphrase);
      }
    });
  }

  async unlockKeys(privateKeyArmored, publicKeyArmored, passphrase) {
    const blocker = document.getElementById('e2ee-blocker-container');
    try {
      await registerKey(privateKeyArmored, publicKeyArmored, passphrase);
      if (blocker) {
        Turbolinks.visit('/dashboard', { flush: true, cacheRequest: false })
      } else {
        turbolinksReload();
      }
    } catch (pgpErr) {
      console.log(pgpErr);
    }
    turbolinksReload();
  }
}
