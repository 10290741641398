import { Controller } from "stimulus";
import { generateKey, registerKey, basicDigestMessage } from "../model/crypto";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["privateKey", "publicKey", "userName",
                    "userHashid", "password", "passwordRepeat",
                    "passwordError", "generateForm", "passwordRepeatError",
                    "autoGenerateForm", "submitButton",
                    "toggleVisible", "toggleInvisible"];

  static values = { passwordLengthMessage: String,
                    passwordRepeatMessage: String,
                    passwordIsSameMessage: String,
                    redirectUrl: String,
                    url: String }

  async connect() {
    if (this.hasAutoGenerateFormTarget) {
      console.log(`connect: ${window.location.pathname}`);
      await this.autoGenerateKeyAction();
      console.log(`connect_afterawait: ${window.location.pathname}`);
    }
  }

  async autoGenerateKeyAction() {
    console.log(`autogen: ${window.location.pathname}`);
    if (sessionStorage.pubKey && sessionStorage.privKey && sessionStorage.passphrase) return;

    console.log(`autogen_non_ret: ${window.location.pathname}`);
    const IdData = { name: this.userHashidTarget.value,
                     email: this.userHashidTarget.value + '@ihrarztonline.de' };
    const passphrase = this.generateRand(20);
    const key = await generateKey(IdData, passphrase);
    this.privateKeyTarget.value = key.privateKeyArmored;
    this.publicKeyTarget.value = key.publicKeyArmored;
    const formTarget = this.autoGenerateFormTarget;

    const privateKeyTarget = this.privateKeyTarget;
    const publicKeyTarget = this.publicKeyTarget;

    // await this.register(key, passphrase);
    // this.register(key, passphrase)
    // .then(() => {
      console.log(`autogen_prefetch: ${window.location.pathname}`);
      fetch(this.urlValue, {
        method: this.hasMethodValue ? this.methodValue : 'POST',
        body: new FormData(formTarget),
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        credentials: 'same-origin'
      })
      .then(response => {
          privateKeyTarget.value = "";
          publicKeyTarget.value = "";
          console.log(`autogen_post_fetch: ${window.location.pathname}`);
          if (response.ok && !(sessionStorage.pubKey && sessionStorage.privKey && sessionStorage.passphrase)) {
            this.register(key, passphrase);
            console.log(`autogen_post_fetch_register: ${window.location.pathname}`);
            Turbolinks.visit(this.redirectUrlValue,
                             { flush: true, cacheRequest: false });
          } else {
            console.log(`autogen_post_fetch_error: ${window.location.pathname}`);
          }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // });
  }

  // generate a key via `crypto.js` and fill it into the forms
  async generateKeyAction(event) {
    event.preventDefault();
    const IdData = { name: this.userNameTarget.value,
                     email: this.userHashidTarget.value + '@ihrarztonline.de' };
    const passphrase = this.passwordTarget.value.trim();
    const key = await generateKey(IdData, passphrase);
    this.privateKeyTarget.value = key.privateKeyArmored;
    this.publicKeyTarget.value = key.publicKeyArmored;
    await this.register(key, passphrase);
    this.clearDigestHex();
    this.submitForm();
  }

  validateForm(event) {
    const form = this.generateFormTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
  }

  validatePassword() {
    const password = this.passwordTarget.value.trim();
    if (password.length === 0) {
      this.passwordTarget.setCustomValidity('');
      return;
    }
    basicDigestMessage(password).then((digestHex) => {
      if (digestHex  === this.getDigestHex()) {
        this.passwordTarget.setCustomValidity(this.passwordIsSameMessageValue);
        this.passwordErrorTarget.textContent = this.passwordIsSameMessageValue;
      } else {
        this.passwordTarget.setCustomValidity('');
      }
    })
  }

  validatePasswords() {
    const password = this.passwordTarget.value.trim();
    const passwordRepeat = this.passwordRepeatTarget.value.trim();
    if (password.length === 0 || passwordRepeat.length === 0) {
      this.passwordRepeatTarget.setCustomValidity('');
      this.validatePassword();
      return;
    }
    if (password.length >= 12) {
      if (password !== passwordRepeat) { // && password.length >= 12) {
        this.passwordRepeatTarget.setCustomValidity(this.passwordRepeatMessageValue);
        this.passwordRepeatErrorTarget.textContent = this.passwordRepeatMessageValue;
      } else {
        this.passwordRepeatTarget.setCustomValidity('');
      }
    }
    else {
      this.passwordRepeatTarget.setCustomValidity(this.passwordLengthMessageValue);
      this.passwordRepeatErrorTarget.textContent = this.passwordLengthMessageValue;
    }
    this.validatePassword();
  }

  // when submitting the form, register the key in local storage
  // async register() {
  async register(key, passphrase) {
    if (key) {
      await registerKey(key.privateKeyArmored, key.publicKeyArmored, passphrase);
    }
  }

  clearDigestHex() {
    if (sessionStorage.getItem("digestHex")) {
      sessionStorage.removeItem("digestHex");
    }
  }

  getDigestHex() {
    return sessionStorage.getItem("digestHex");
  }

  toggleVisibility() {
    const password = this.passwordTarget;
    const passwordRepeat = this.passwordRepeatTarget;
    const toggleVisible = this.toggleVisibleTarget;
    const toggleInvisible = this.toggleInvisibleTarget;
    if (password.type === 'password') {
      password.type = 'text'
      passwordRepeat.type = 'text'
      toggleVisible.classList.add('d-none')
      toggleInvisible.classList.remove('d-none')
    } else {
      password.type = 'password'
      passwordRepeat.type = 'password'
      toggleInvisible.classList.add('d-none')
      toggleVisible.classList.remove('d-none')
    }
  }

  dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }

  generateRand(len) {
    const arr = new Uint8Array((len || 40) / 2)
    crypto.getRandomValues(arr)
    return Array.from(arr, this.dec2hex).join('')
  }

  submitForm() {
    // Rails.fire(this.generateFormTarget, 'submit');
    // const submitButton = this.submitButtonTarget
    const privateKeyTarget = this.privateKeyTarget;
    const publicKeyTarget = this.publicKeyTarget;
    // submitButton.classList.add('disabled');
    // submitButton.classList.setAttribute('disabled');
    // submitButton.classList.setAttribute('tabindex', '-1');
    Rails.ajax({
      type: "post",
      url: this.urlValue,
      data: new FormData(this.element), // could also use this.generateFormTarget
      success: function(data) {
        // just in case
        privateKeyTarget.value = "";
        publicKeyTarget.value = "";
      }
    })
  }
}
