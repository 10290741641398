import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import WaveSurfer from "../lib/wavesurfer.js/wavesurfer";
import * as Wave from "../model/wave";
import * as bootstrap from 'bootstrap';
import { spinnerShow, spinnerHide } from "../model/spinner";
import { unlockedKeyPresent, encryptFile, readArmoredPublicKeys } from "../model/crypto";

export default class extends Controller {
  static targets = ["stethoscopeAudio",
                    "wavesurferModal",
                    "wavesurferElement",
                    "publicKeyId",
                    "uploadAudioButton",
                    "micSymbol",
                    "zoomSlider",
                    "stethoscopeDeviceView"];
  static values = { streaming: Boolean,
                    publicKeysUrl: String };

  connect() {
    const stethoscopeDeviceView = this.stethoscopeDeviceViewTarget;
    this.wave = Wave
    this.wave.setCanvas(document.createElement("canvas"));
    this.wave.canvas.classList = "stethoscope-canvas";
    this.recordButton = document.createElement("button");
    this.recordButton.className = "btn btn-primary record-btn";
    this.recordButton.dataset['action'] = 'mouseup->stethoscope#startRecording';
    this.stopButton = document.createElement("button");
    this.stopButton.className = "btn btn-primary record-btn d-none";
    this.stopButton.dataset['action'] = 'mouseup->stethoscope#stopRecording';
    const micSymbol = document.createElement("i");
    micSymbol.className = "fa fa-microphone";
    const stopSymbol = document.createElement("i");
    stopSymbol.className = "fa fa-stop";
    this.recordButton.appendChild(micSymbol);
    this.stopButton.appendChild(stopSymbol);
    stethoscopeDeviceView.appendChild(this.wave.canvas);
    stethoscopeDeviceView.appendChild(this.recordButton);
    stethoscopeDeviceView.appendChild(this.stopButton);
    this.wave.setupStream(this.stethoscopeAudioTarget.srcObject);
    this.wave.setupCanvas();
    this.streamingHasStarted = true;
    this.streamingValue = true;
    this.setupRecorder();
    this.chunks = [];
  }

  streamingValueChanged(changedValue) {
    if (this.streamingHasStarted && !changedValue) {
      // console.log('soft teardown');
      this.wave.teardown();
    }
  }

  setupRecorder() {
    this.wave.setupRecorder(new window.MediaRecorder(this.stethoscopeAudioTarget.srcObject));
    this.wave.recorder.ondataavailable = (event) => this.saveChunkToRecording(event) ;
    this.wave.recorder.onstop = () => this.saveRecording();
  }

  // toggleRecording () {
    // if (this.isRecording) {
      // this.stopRecording();
    // } else {
      // this.startRecording();
    // }
  // }

  requestRecordingPermission() {
    const roomName = document.querySelector('#roomName').dataset['roomName'];
    const currentUserId = document.querySelector('#currentUser').dataset["currentUserId"];
    this.broadcastData({ type: 'REQUEST_RECORDING_PERMISSION',
                         from: currentUserId,
                         roomName: roomName });
  }

  startRecording () {
    const mainRoomContainer = document.getElementById('mainRoomContainer');
    const recordingPermissionValue = mainRoomContainer.dataset['webrtcRoomRecordingsAllowedValue'];
    if (recordingPermissionValue !== 'true' && recordingPermissionValue !== '1') {
      this.requestRecordingPermission();
      return;
    }

    this.isRecording = true;
    this.recordButton.classList.add('d-none');
    this.stopButton.classList.remove('d-none');
    this.stopButton.classList.add('record-btn--active');
    this.stopButton.classList.add('record-btn--recording');
    this.wave.recorder.start();
  }

  stopRecording () {
    this.isRecording = false;
    this.recordButton.classList.remove('d-none');
    this.stopButton.classList.add('d-none');
    this.stopButton.classList.remove('record-btn--active');
    this.stopButton.classList.remove('record-btn--recording');
    this.wave.recorder.stop();
  }

  saveChunkToRecording(event) {
    this.chunks.push(event.data);
  }

  saveRecording() {
    // this.recording = URL.createObjectURL(new Blob(this.chunks, { 'type' : 'audio/ogg; codecs=opus' }));
    this.recording = new Blob(this.chunks, { 'type' : 'audio/ogg; codecs=opus' });
    // this.recording = new Blob(this.chunks);
    // console.log(this.chunks)
    // console.log(this.recording)
    this.chunks = [];
    // console.log(this.recording);

    // audioPlayer.setAttribute('src', recording);
    // playButton.classList.remove('button--disabled');
    this.prepareModal();
  }

  prepareModal() {
    // const wavesurferContainer = this.wavesurferContainerTarget;
    const consultationId = document.querySelector("[data-consultation-id]").dataset['consultationId'];
    fetch(`/consultations/${consultationId}/wave-modal`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => {
      response.text().then(responseText => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(responseText, 'text/html');
        this.element.append(doc.querySelector('div'));
      }).then(() => {
        this.setupWavesurfer();
        this.showModal();
      });
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  wavesurferModalShown() {
    const slider = this.zoomSliderTarget;
    slider.value = this.wavesurfer.params.minPxPerSec;
    slider.min = this.wavesurfer.params.minPxPerSec;
    slider.max = 1000;
    // set initial zoom to match slider value
    this.wavesurfer.zoom(slider.value);
  }

  setupWavesurfer() {
    const wavesurferElement = this.wavesurferElementTarget;
    this.wavesurfer = WaveSurfer.create({
      container: wavesurferElement,
      waveColor: "black",
      height: 512,
      forceDecode: true,
      responsive: true,
      scrollParent: true,
    });
    this.wavesurfer.loadBlob(this.recording);
  }

  async uploadAudio() {
    this.element.dataset.cryptInProgress = true;
    const consultationId = document.querySelector("[data-consultation-id]").dataset['consultationId'];
    const roomName = document.querySelector('#roomName').dataset['roomName'];
    const currentUserId = document.querySelector('#currentUser').dataset["currentUserId"];
    const uploadUrl = `/consultations/${consultationId}`;
    spinnerShow();
    const publicKeyIdTargets = this.publicKeyIdTargets;
    const publicKeyIds = publicKeyIdTargets.map( function(publicKeyId) { return publicKeyId.textContent.trim(); } );
    this.uploadAudioButtonTarget.setAttribute("disabled", "disabled");
    const publicKeys = await this.getPublicKeys(publicKeyIds);
    const publicKeyObjs = await readArmoredPublicKeys(publicKeys);
    const publicKeyObjsResolved = await Promise.all(publicKeyObjs);
    const datestring = new Date().toISOString();
    const filename = `recording_${datestring}.ogg`
    const plaintextFile = new File([this.recording],
                                   filename,
                                   { type: this.recording.type,
                                     lastModified: new Date().getTime() });

    let encrypted = await encryptFile(plaintextFile, publicKeyObjsResolved)

    const formData = new FormData();
    formData.append('consultation[consultation_id]', consultationId);
    formData.append('consultation[consultation_upload]', encrypted, filename)
    try {
      fetch(uploadUrl, {
        method: 'PUT',
        body: formData,
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        this.broadcastData({
          type: 'REFRESH_UPLOADS',
          from: currentUserId,
          roomName: roomName,
          consultationId: consultationId,
        });
      });
    }
    catch (err) {
      console.error(err);
      this.uploadAudioButtonTarget.removeAttribute('disabled');
    }
    finally {
      this.element.dataset.cryptInProgress = false;
      spinnerHide();
      this.dismissWavesurferModal();
    }
  }

  zoom() {
    this.wavesurfer.zoom(Number(this.zoomSliderTarget.value));
  }

  play() {
    // console.log('play')
    this.wavesurfer.playPause();
  }

  dismissWavesurferModal() {
    const wavesurferModalElement = this.wavesurferModalTarget;
    const wavesurferModal = bootstrap.Modal.getInstance(wavesurferModalElement);
    this.wavesurfer.stop();
    wavesurferModal.hide();
  }

  wavesurferModalDismissed() {
    this.wavesurfer.destroy();
    this.wavesurferModalTarget.remove();
    this.stethoscopeAudioTarget.srcObject.getAudioTracks()[0].enabled = true;
  }

  showModal() {
    this.stethoscopeAudioTarget.srcObject.getAudioTracks()[0].enabled = false;
    const wavesurferModalElement = this.wavesurferModalTarget;
    const wavesurferModal = new bootstrap.Modal(wavesurferModalElement);
    wavesurferModal.show();
  }
  cleanupBeforeReload() {
    // console.log("beforeReloadCalled");
    this.teardown();
  }

  disconnect() {
    // console.log("disconnect()");
    this.teardown();
  }

  teardown() {
    console.log("stethoscope teardown");
    this.chunks = [];
    this.wave.teardown();
  }

  async getPublicKeys(publicKeyIds) {
    const response = await fetch(this.publicKeysUrlValue, {
      method: 'post',
      body: JSON.stringify(publicKeyIds),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });

    return response.json();
  }

  async broadcastData(data) {
    let formData = new FormData();
    for (const [k, v] of Object.entries(data)) {
      formData.append(k, v);
    }

    fetch('/sessions', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: formData,
        credentials: 'same-origin'
    })
    .then(response => {
      response.text().then(responseText => {
        console.log(responseText);
      })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}
