import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    consultationId: String,
    roomName: String,
    currentUserId: String
  }

  connect() {
    this.element['ajaxSuccessController'] = this
  }

  formAjaxSuccess(response) {
    this.updatePatientNote(response);
  }

  updatePatientNote(response, ajaxReplaceElement = this.element) {
    const consultationId = this.consultationIdValue;
    ajaxReplaceElement = this.element.parentElement;
    response.text().then(responseText => {
      ajaxReplaceElement.innerHTML = '';
      ajaxReplaceElement.innerHTML = responseText;
    });
    this.broadcastData({
      type: 'REFRESH_FORMS',
      from: this.currentUserIdValue,
      consultation: consultationId,
      roomName: this.roomNameValue
    });
  };

  broadcastData(data) {
      $.ajax({url: "/sessions", type: "post", data});
  }
}
