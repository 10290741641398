import { Controller } from "stimulus";
import { turbolinksReload } from '../src/turbolinks_reload';
import * as bootstrap from 'bootstrap';
import Rails from "@rails/ujs";
import consumer from "../channels/consumer";
export default class extends Controller {

  static targets = [ "consultationList",
                     "pendingPlaceholder",
                     "activePlaceholder",
                     "pendingCount",
                     "activeCount",
                     "patientsCount",
                     "patientList",
                     "consultationActiveList",
                     "consultationPendingList" ]
  static values = { currentUser: String }

  async connect() {
    this.subscription = await consumer.subscriptions.create(
      {
        channel: "NotificationChannel",
        id: this.currentUserValue
      },
      {
        connected: () => console.log('connected'),
        received: (data) => this.received(data)
      }
    )
    this.cleanedUp = false;
  }

  async received(data) {
  switch (data.type) {
    case 'newConsultation':
      // (refresh occupiedness)
      if (this.hasConsultationListTarget) this.updateElementStates(data);
      this.updateCounts(data);
      return this.notifyPatient(data);
    case 'newPatient':
      this.updateCounts(data);
      return this.notifyDoctor(data);
    case 'updatePanel':
      this.updatePanel(data);
      return this.notifyDoctorSimple(data);
    default:
      return;
  }
// }
  }
  freshToast() {
    const toastContainer = document.querySelector('.toast-container');
    const toastEl = document.createElement('div');
    toastEl.className = 'toast text-primary';
    toastEl.setAttribute('aria-live', 'polite');
    toastEl.setAttribute('aria-atomic', 'true');
    toastEl.setAttribute('role', 'status');
    // NOTE: 5k is default
    toastEl.setAttribute('data-bs-delay', '5000');
    const toastBodyEl = document.createElement('div');
    toastBodyEl.className = 'toast-body';
    const wrapperDiv = document.createElement('div');
    wrapperDiv.className = 'mt-2 pt-2 border-top';
    return [toastContainer, toastEl, toastBodyEl, wrapperDiv];
  }

  notifyDoctor(data) {
    const [toastContainer, toastEl, toastBodyEl, wrapperDiv] = this.freshToast();
    if (this.hasPatientListTarget) {
      if (this.patientListTarget.childElementCount >= 5) this.patientListTarget.lastElementChild.remove();
    }

    fetch(`/patients/${data.patientId}/panel`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => response.text())
    .then(html => {
      if (this.hasPatientListTarget) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        this.patientListTarget.prepend(doc.querySelector('span'))
      }
      wrapperDiv.innerHTML = html;
      toastBodyEl.textContent = 'Neuer Patient!';
      toastBodyEl.append(wrapperDiv);
      toastEl.append(toastBodyEl);
      toastContainer.append(toastEl);
      const toast = new bootstrap.Toast(toastEl);
      toastEl.addEventListener('hidden.bs.oast', () => { toastEl.remove() });
      toast.show();
    });
  }

  notifyDoctorSimple(data) {
    const [toastContainer, toastEl, toastBodyEl, wrapperDiv] = this.freshToast();
    toastBodyEl.textContent = `Sprechstunde ${data.consultationId} bereit!`;
    toastBodyEl.append(wrapperDiv);
    toastEl.append(toastBodyEl);
    toastContainer.append(toastEl);
    const toast = new bootstrap.Toast(toastEl);
    toastEl.addEventListener('hidden.bs.oast', () => { toastEl.remove() });
    toast.show();
  }

  notifyPatient(data) {
    const [toastContainer, toastEl, toastBodyEl, wrapperDiv] = this.freshToast();
    // for now, always waiting room on click
    const buttonA = document.createElement('a');
    buttonA.className = 'btn btn-sm bg-primary text-white';
    buttonA.setAttribute('href', `/rooms/Wait-${data.consultationId}`);
    buttonA.textContent = 'Wartezimmer';
    wrapperDiv.append(buttonA);
    toastBodyEl.textContent = `Neue Sprechstunde bei ${data.from}!`;
    toastBodyEl.append(wrapperDiv);
    toastEl.append(toastBodyEl);
    toastContainer.append(toastEl);
    const toast = new bootstrap.Toast(toastEl);
    toastEl.addEventListener('hidden.bs.oast', () => { toastEl.remove() });
    toast.show();
  }

  updatePanel(data) {
    const consultButtonElement = document.querySelector(`#consultButton${data.consultationId}`)
    const waitButtonElement = document.querySelector(`#waitButton${data.consultationId}`)
    if (consultButtonElement != undefined && data.consultButtonState === 'active') {
        if (consultButtonElement.classList.contains('disabled')) consultButtonElement.classList.remove('disabled');
    }
    if (waitButtonElement != undefined && data.waitButtonState === 'active') {
        if (waitButtonElement.classList.contains('disabled')) waitButtonElement.classList.remove('disabled');
    }
  }

  updateElementStates(data) {
    // remember pending counter
    fetch(`/consultations/${data.consultationId}/panel`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => response.text())
    .then(html => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      if (data.consultationState === 'pending') {
        if (this.hasPendingPlaceholderTarget) {
          this.pendingPlaceholderTargets.forEach( (placeholderTarget) => { placeholderTarget.remove() });
        }
        this.consultationPendingListTarget.prepend(doc.querySelector('div'));
      } else if (data.consultationState === 'active') {
        if (this.hasActivePlaceholderTarget) {
          this.activePlaceholderTargets.forEach( (placeholderTarget) => { placeholderTarget.remove() });
        }
        this.consultationActiveListTarget.prepend(doc.querySelector('div'));
      }
    })
  }

  updateCounts(data) {
    if (data.consultationState === 'active' && this.hasActiveCountTarget) {
      const incrementElement = this.activeCountTarget;
      const incrementedValue = parseInt(this.activeCountTarget.textContent) + 1;
      incrementElement.textContent = incrementedValue;
    } else if (data.consultationState === 'pending' && this.hasPendingCountTarget) {
      const incrementElement = this.pendingCountTarget;
      const incrementedValue = parseInt(this.pendingCountTarget.textContent) + 1;
      incrementElement.textContent = incrementedValue;
    } else if (this.hasPatientsCountTarget) {
      const incrementElement = this.patientsCountTarget;
      const incrementedValue = parseInt(this.patientsCountTarget.textContent) + 1;
      incrementElement.textContent = incrementedValue;
    }
  }

  cleanupBeforeReload() {
    console.log('cleanupBeforeReload');
    this.teardown();
  }

  disconnect() {
    console.log('disconnect()');
    this.teardown();
  }

  teardown() {
    if (this.cleanedUp) return;
    this.cleanedUp = true;
    try {
      consumer.subscriptions.remove(this.subscription);
      consumer.disconnect();
      console.log('cleanup: disconnected');
    } catch (error) {
      console.error('Error disconnecting WebSocket Subscription: ', error);
    }
    const toastElList = [].slice.call(document.querySelectorAll('.toast'))
    toastElList.map(function (toastEl) {
      // const toast =  new bootstrap.Toast(toastEl)
      // toast.dispose();
      toastEl.remove();
    });
  }
}
