// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
import * as bootstrap from 'bootstrap'
import "../stylesheets/application"

import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/free-regular-svg-icons';
import '@fortawesome/free-solid-svg-icons';
import '@fortawesome/free-brands-svg-icons';

import 'whatwg-fetch'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// import WaveSurfer from "wavesurfer.js";
// import MicrophonePlugin from "wavesurfer.js/src/plugin/microphone.js";
import 'controllers' // for stimulus

// still needed?
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// NOTE: maybe alternatively:
// document.addEventListener("turbolinks:load", function() {
    // $(function () {
// $('[data-bs-toggle="tooltip"]').tooltip()
        // $('[data-bs-toggle="popover"]').popover()
    // })
// })

// document.addEventListener("DOMContentLoaded", function(event) {
  // var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  // var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    // return new bootstrap.Popover(popoverTriggerEl)
  // })
//
  // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    // return new bootstrap.Tooltip(tooltipTriggerEl)
  // })
// });

document.addEventListener("turbolinks:load", function(_event) {
  // var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  // var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    // return new bootstrap.Popover(popoverTriggerEl)
  // })

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});

document.addEventListener("turbolinks:load", function(_event) {
  [].forEach.call(document.querySelectorAll('.toast'), function(val) {
    var toast = new bootstrap.Toast(val);
    toast.show();
    val.addEventListener('hidden.bs.toast', function () {
      toast.dispose();
    });
  })
});
