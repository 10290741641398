import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'nameLine',
                     'establishmentLine',
                     'streetLine',
                     'addressLine',
                     'city',
                     'zip',
                     'street',
                     'establishment',
                     'firstname',
                     'lastname',
                     'title',
                     'affectedPersons',
                     'affectedCategories',
                     'authorizedPersons',
                     'regulatoryBody',
                     'dataSecurityOfficial',
                     'authorizedPersonsLine',
                     'regulatoryBodyLine',
                     'dataSecurityOfficialLine',
                     'affectedCategoriesLine',
                     'affectedPersonsLine'
    ];

    fieldChange(event){

      const title = this.titleTarget;
      const firstname = this.firstnameTarget;
      const lastname = this.lastnameTarget;
      const establishment = this.establishmentTarget;
      const street = this.streetTarget;
      const zip = this.zipTarget;
      const city = this.cityTarget;

      const currentEventTarget = event.currentTarget;
      const mainDataElements = [title, firstname, lastname, establishment, street, zip, city];
      const mainDataChanged = mainDataElements.some((element) => currentEventTarget === element);

      // if (mainDataChanged) this.changeAddressLines();

      const regulatoryBody = this.regulatoryBodyTarget;
      const dataSecurityOfficial = this.dataSecurityOfficialTarget;
      const authorizedPersons = this.authorizedPersonsTarget;
      const affectedPersons = this.affectedPersonsTarget;
      const affectedCategories = this.affectedCategoriesTarget;

      if (mainDataChanged) {
        this.changeAddressLines();
      } else if (regulatoryBody === currentEventTarget) {
        this.changeRegulatoryBodyLines();
      } else if (affectedPersons === currentEventTarget) {
        this.changeAffectedPersonsLines();
      } else if (affectedCategories === currentEventTarget) {
        this.changeAffectedCategoriesLines();
      } else if (dataSecurityOfficial === currentEventTarget) {
        this.changeDataSecurityOfficialLines();
      } else if (authorizedPersons === currentEventTarget) {
        this.changeAuthorizedPersonsLines();
      }
    }

    changeAddressLines() {
      const title = this.titleTarget.value.trim();
      const firstname = this.firstnameTarget.value.trim();
      const lastname = this.lastnameTarget.value.trim();
      const establishment = this.establishmentTarget.value.trim();
      const street = this.streetTarget.value.trim();
      const zip = this.zipTarget.value.trim();
      const city = this.cityTarget.value.trim();

      const nameLine = this.nameLineTarget;
      const establishmentLine = this.establishmentLineTarget;
      const streetLine = this.streetLineTarget;
      const addressLine = this.addressLineTarget;

      const firstLineText = `${title.length > 0 ? title + ' ' : ''}${firstname} ${lastname}`;

      // const dataSecurityOfficialLine = this.dataSecurityOfficialLineTarget;
      // const dataSecurityOfficial = this.dataSecurityOfficialTarget.value?.trim();
      // if (dataSecurityOfficial.length === 0) {
        // dataSecurityOfficialLine.innerText = firstLineText;
      // }
      //
      nameLine.textContent = firstLineText;
      establishmentLine.textContent = establishment;
      streetLine.textContent = street;
      addressLine.textContent = `${zip} ${city}`
    }

    changeRegulatoryBodyLines() {
      const regulatoryBody = this.regulatoryBodyTarget.value?.trim();
      const regulatoryBodyLine = this.regulatoryBodyLineTarget;

      regulatoryBodyLine.textContent = regulatoryBody;
    }

    changeAuthorizedPersonsLines() {
      const authorizedPersons = this.authorizedPersonsTarget.value;
      const authorizedPersonsLine = this.authorizedPersonsLineTarget;

      const authorizedPersonsLines = authorizedPersons.split(/\r\n|\n\r|\n|\r/);

      authorizedPersonsLine.textContent = '';

      for (const line of authorizedPersonsLines) {
        const newLine = document.createElement('p');
        newLine.classList = 'mb-0';
        newLine.textContent = line;
        authorizedPersonsLine.append(newLine);
      }
    }

    changeDataSecurityOfficialLines() {
      const dataSecurityOfficial = this.dataSecurityOfficialTarget.value?.trim();
      const dataSecurityOfficialLine = this.dataSecurityOfficialLineTarget;
      // if (dataSecurityOfficial.length === 0) {
        // const title = this.titleTarget.value;
        // const firstname = this.firstnameTarget.value;
        // const lastname = this.lastnameTarget.value;
        // dataSecurityOfficialLine.textContent = `${title.length > 0 ? title + ' ' : ''}${firstname} ${lastname}`;
      // } else {
        // dataSecurityOfficialLine.textContent = dataSecurityOfficial;
      // }
      //
      dataSecurityOfficialLine.textContent = dataSecurityOfficial;
    }

    changeAffectedCategoriesLines() {
      const affectedCategories = this.affectedCategoriesTarget.value;
      const affectedCategoriesLine = this.affectedCategoriesLineTarget;

      const affectedCategoriesLines = affectedCategories.split(/\r\n|\n\r|\n|\r/);

      affectedCategoriesLine.textContent = '';

      for (const line of affectedCategoriesLines) {
        const newLine = document.createElement('p');
        newLine.classList = 'mb-0';
        newLine.textContent = line;
        affectedCategoriesLine.append(newLine);
      }
    }

    changeAffectedPersonsLines() {
      const affectedPersons = this.affectedPersonsTarget.value;
      const affectedPersonsLine = this.affectedPersonsLineTarget;

      const affectedPersonsLines = affectedPersons.split(/\r\n|\n\r|\n|\r/);

      affectedPersonsLine.textContent = '';

      for (const line of affectedPersonsLines) {
        const newLine = document.createElement('p');
        newLine.classList = 'mb-0';
        newLine.textContent = line;
        affectedPersonsLine.append(newLine);
      }
    }
}
