import consumer from "../channels/consumer";
// import WaveSurfer from "../lib/wavesurfer.js/wavesurfer";
// import MicrophonePlugin from "../lib/wavesurfer.js/plugins/wavesurfer.microphone";
import Rails from "@rails/ujs";
import adapter from 'webrtc-adapter';
// import jQuery from 'jquery';
import Swal from 'sweetalert2';

// NOTE: VARS
//
//
// Broadcast Types
export const SESSION_ID = "SESSION_ID";
export const JOIN_ROOM = "JOIN_ROOM";
export const EXCHANGE = "EXCHANGE";
export const JOIN_DEVICE = "JOIN_DEVICE";
export const EXCHANGE_DEVICE = "EXCHANGE_DEVICE";
export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_DEVICE = "REMOVE_DEVICE";
export const SHOW_GUIDE = "SHOW_GUIDE";
export const LETTER = "LETTER";
export const REFRESH_FORMS = "REFRESH_FORMS";
export const REFRESH_UPLOADS = "REFRESH_UPLOADS";
export const END_SESSION = "END_SESSION";
export const STETHOSCOPE_NEGOTIATION = "STETHOSCOPE_NEGOTIATION";
export const REQUEST_RECORDING_PERMISSION = "REQUEST_RECORDING_PERMISSION";
export const RESPOND_RECORDING_PERMISSION = "RESPOND_RECORDING_PERMISSION";

// TODO: to be set by prod/staging/dev env later
// disables non-error console-output
// const DEBUG = true;
//
// devices:
const ENDOCAM_LABELS = [
  "0c45:64ab",
  "a168:09a5",
  "1bcf:2085",
  "USB 2.0 Camera",
  "USB Camera",
  "1bcf:2c9a",
  "USB2.0 PC CAMERA",
  "1908:2311",
  "PLX Camera",
  "Teslong Camera",
  "f007:c999",
  "f007:a999",
];
const STETHOSCOPE_LABELS = [
  // "Analog Mono",
  "PCM2900C Audio CODEC Analog",
  "USB Audio Device",
  "USB PnP Audio Device",
  "0d8c:0134",
  "08bb:29c0",
  "0d8c:0014",
  "uore",
];

// DOM Elements
// const currentUser = document.getElementById("currentUser").dataset
  // .currentUserId
// const selfView = document.getElementById("selfView");
// const remoteViewContainer = document.getElementById("remoteViewContainer");
// const remoteDeviceViewContainer = document.getElementById(
  // "remoteDeviceViewContainer"
// );
// const deviceDisconnectButton = document.getElementById('deviceDisconnectButton');
// Configuration
const constraints = {
  audio: true,
  video: true,
};
// Global Objects
let rtcConfig;
export let pcPeers = new Map();
export let pcMap = new Map();
export let mainPc;
export let localStream;
export let deviceDataChannel;
export let localDeviceStreamMap = new Map();
export let remoteUser;
let sinkDeviceId;
// export let remoteUserJoined = false;
// export let localUserJoined = false;
export let proposedId;
export let acceptedId;
export let remoteViewStreamId = "";
export let handledTrackIds = new Set();
export let handledDeviceIds = new Set();
export let mainAudioTrackId;
export let localDeviceIds = new Set();
export let testingDeviceIds = new Set();
export let localDeviceIdsInUse = new Set();
export let localStethoscopes = new Set();
export let localEndocams = new Set();
export let deviceSelector;
// export let wavesurfer;
export let subscription;
export let remoteViewContainer;
export let localViewContainer;
export let remoteAudioViewContainer;
export let remoteDeviceViewContainer;
export let localDeviceViewContainer;
// NOTE: INIT
//
export let roomName;
export let currentUser;
let stethoscopeStatusString;
//

export async function requestIceCandidates(roomNameValue) {
  roomName = roomNameValue;
  fetch(`/rooms/${roomName}/turn`, {
    method: 'GET',
    headers: {
      'X-CSRF-Token': Rails.csrfToken()
    },
    credentials: 'same-origin'
  })
  .then(response => {
    response.json().then(responseJSON => {
      rtcConfig = responseJSON;
      console.log("Could get RTC config!");
    })
  })
  .catch((error) => {
    console.error("Could not get RTC config!");
    console.error('Error:', error);
  });
  // $.ajax({
    // type: "GET",
    // url: `/rooms/${roomName}/turn`,
    // success(data) {
      // rtcConfig = data;
      // // console.log("Could get RTC config!");
      // return true;
    // },
    // error() {
      // console.error("Could not get RTC config!");
      // return false;
    // },
  // });
}

export async function initializeLocalStream(selfView, outputSelector) {
  // NOTE: request permissions and don't to anything with them yet.
  await navigator.mediaDevices.getUserMedia(constraints);
  const devicesToSort = await navigator.mediaDevices.enumerateDevices();
  const audioOutputDevices = devicesToSort.filter(device => device.kind === 'audiooutput');
  let sinkDevices = [];
  // console.log('outs: ', audioOutputDevices);
  // TODO: move this to after established instead?
if (audioOutputDevices.length > 0 && audioOutputDevices[0].label.match(/(default - |communication - |standard - )(.+)/i) && audioOutputDevices[0].groupId.toLowerCase() !== 'default'){
    let groupId =  audioOutputDevices[0].groupId;
    sinkDevices = audioOutputDevices.filter(device => {
      console.log('expected groupId: ', groupId);
      console.log('devGroupId: ' + device.groupId + ' label: ' + device.label + ' id: ' + device.deviceId);
      return !device.deviceId.toLowerCase().match(/(default|communication|standard)/i) && device.groupId === groupId;
    });
  }
  console.log('sinkDevices: ', sinkDevices);
  if (sinkDevices.length > 0) {
    sinkDeviceId = sinkDevices[0].deviceId;
  }
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      localStream = stream;
      selfView.srcObject = stream;
      selfView.muted = true;
    })
  .then(initializeAudioOutputSelector(outputSelector))
  .then(() => {
    navigator.mediaDevices.enumerateDevices()
    .then(function(devices) {
      devices.forEach(function(device) {
        console.log('init: ' + device.kind + ": " + device.label +
        " id = " + device.deviceId + 'groupId: ' + device.groupId);
      });
    })
  }).catch(logError);
}

async function initializeAudioOutputSelector(outputSelector) {
  navigator.mediaDevices.enumerateDevices().then((devices) => {
    let masterOutputSelector = document.createElement("select");
    masterOutputSelector.classList = "form-control";
    // let labels = localStream.getTracks().map(track => track.label);
    devices.forEach((device) => {
      let option = document.createElement("option");
      option.value = device.deviceId;
      if (device.kind === "audiooutput") {
        option.text =
          device.label || `Ausgabegerät ${masterOutputSelector.length + 1}`;
        masterOutputSelector.appendChild(option);
      }
    });
    // TODO: set to output if sinkDeviceId present
    let newOutputSelector = masterOutputSelector.cloneNode(true);
    newOutputSelector.addEventListener("change", changeAudioDestination);
    newOutputSelector.dataset['webrtcRoomTarget'] = 'outputSelector';
    newOutputSelector.id = 'outputSelector';
    outputSelector.parentNode.replaceChild(
      newOutputSelector,
      outputSelector
    );
  });
}
export async function handleJoinSession(currentUserId, remoteViewContainerElement) {
  currentUser = currentUserId;
  remoteViewContainer || (remoteViewContainer = remoteViewContainerElement);
  localViewContainer || (localViewContainer = document.getElementById('localViewContainer'));
  let iterations = 0;
  let myInterval = setInterval(async function () {
    if (iterations++ >= 200) clearInterval(myInterval);
    if (typeof localStream == "undefined") return;
    clearInterval(myInterval);

    subscription = await consumer.subscriptions.create(
      {
        channel: "SessionChannel",
        id: roomName,
      },
      {
        connected: () => connectUser(currentUser),
        received: (data) => {
          console.log("received", data);
          if (data.type === LETTER) {
            return letter(data);
          }
          if (data.type === REMOVE_DEVICE) {
            return removeDeviceHandler(data);
          }
          if (data.type === REFRESH_UPLOADS) {
            return refreshUploadedFiles(data);
          }
          if (data.from === currentUser) return;
          switch (data.type) {
            case JOIN_ROOM:
              console.log("reached join room, from:", data.from);
              return joinRoom(data);
            case JOIN_DEVICE:
              return addDevice(data);
            case SESSION_ID:
              return acceptSessionId(data);
            case REFRESH_FORMS:
              return refreshForms(data);
            case END_SESSION:
              return endSession(data);
            case EXCHANGE:
              if (data.to !== currentUser) return;
              return exchange(data);
            case EXCHANGE_DEVICE:
              if (data.to !== currentUser) return;
              return exchangeDevice(data);
            case REMOVE_USER:
              return removeUser(data);
            case SHOW_GUIDE:
              return displayGuide(data);
            case STETHOSCOPE_NEGOTIATION:
              return updateStethoscopeStatus(data);
            case REQUEST_RECORDING_PERMISSION:
              return recordingPermissionRequest(data);
            case RESPOND_RECORDING_PERMISSION:
              return recordingPermissionResponse(data);
            default:
              return;
          }
        },
      }
    );
  }, 100);
  if (!localStream) {
    console.log('remote Stream not connected');
  }
  // console.log("localstream: " + localStream);
}
export function handleLeaveSession(initiatedByRemote) {
  console.log('handleLeaveSession called');

  if (!initiatedByRemote && acceptedId) {
    broadcastData({ type: REMOVE_USER, sessionId: acceptedId, from: currentUser, roomName });
    // remoteUserJoined && (remoteUserJoined = false)
    // localUserJoined && (localUserJoined = false)
  // } else {
    // remoteUserJoined && (remoteUserJoined = false)
  }

  if (document.querySelector("[data-stethoscope-target]")) {
    broadcastData({
      type: STETHOSCOPE_NEGOTIATION,
      from: currentUser,
      to: remoteUser,
      status: 'disconnected',
      roomName,
    });
  }

  let stethoscopeStatusButton = document.querySelector('.stethoscope-status');
  if (stethoscopeStatusButton) {
    stethoscopeStatusButton.remove();
  }
  stethoscopeStatusString = null;

  for (const [_pcName, pc] of pcPeers) {
    if (pc !== undefined) {
      pc.close();
    }
  }

  try {
    let localStreamTracks = localStream.getTracks();
    for (const track of localStreamTracks) {
      track.stop();
    }
  } catch (error) {
    // console.error('No tracks found to stop.');
  }
  try {
    let localStreamTracks = selfView.srcObject.getTracks();
    for (const track of localStreamTracks) {
      track.stop();
    }
  } catch (error) {
    // console.error('No selfView tracks found to stop.');
  }

  let videoSourceObjects = document.querySelectorAll('video')
  let audioSourceObjects = document.querySelectorAll('audio')

  audioSourceObjects.forEach(function(sourceObject) {
    try {
      let localStreamTracks = sourceObject.getTracks();
      for (const track of localStreamTracks) {
        track.stop();
      }
      sourceObject = undefined;
    } catch (error) {
    }
  });

  videoSourceObjects.forEach(function(sourceObject) {
    try {
      let localStreamTracks = sourceObject.getTracks();
      for (const track of localStreamTracks) {
        track.stop();
      }
      sourceObject = undefined;
    } catch (error) {
    }
  });

  try {
    for (const [_deviceId, stream] of localDeviceStreamMap) {
      let localDeviceStreamTracks = stream.getTracks();
      for (const track of localDeviceStreamTracks) {
        track.stop();
      }
    }
  } catch (error) {
    // console.error('No device tracks found to stop.');
//
  localStream = undefined;
  selfView.srcObject = undefined;
  }
  // catch error here:
  try {
    consumer.subscriptions.remove(subscription);
    consumer.disconnect();
  } catch (error) {
    console.error('Error disconnecting WebSocket Subscription: ', error);
  }
  let remoteViewElements = document.querySelectorAll("[id*='View+']")
  remoteViewElements.forEach(function(remoteViewElement) {
    remoteViewElement.remove();
  })

  document.getElementById("placeholder-video-remote").style.display =
    "block";
  // remoteViewContainer.innerHTML = "";
  document.getElementById('remoteDeviceViewContainer').innerHTML = "";
  document.getElementById('remoteAudioViewContainer').innerHTML = "";
  handledTrackIds.clear();
  handledDeviceIds.clear();
  testingDeviceIds.clear();
  pcPeers.clear();
  localDeviceStreamMap.clear();
  localDeviceIds.clear();
  testingDeviceIds.clear();
  localDeviceIdsInUse.clear();
  localStethoscopes.clear();
  localEndocams.clear();
  // make the above "clean-slate" the other side!
  acceptedId = undefined;
  proposedId = undefined;
  if (initiatedByRemote) {
    reInitializeSession();
  }
}

function reInitializeSession() {
  // if (localUserJoined) return;
  requestIceCandidates(roomName)
  .then(initializeLocalStream(selfView, document.getElementById('outputSelector')))
  .then(handleJoinSession(currentUser, document.getElementById('remoteViewContainer')));
}

function createInitialPC(opts) {
  console.log("create initial pc with: ", opts);
  // console.log(opts);
  let userId = opts.userId;
  let isOffer = opts.isOffer;
  let pcName = userId;
  let pc = new RTCPeerConnection(rtcConfig);
  pcMap.set(
    pc,
    new Map([
      ["pcName", pcName],
      ["userId", userId],
    ])
  );
  pcPeers.set(pcName, pc);
  localStream.getTracks().forEach(function (track) {
    track.onended = (_evt) =>  {
      console.log(`ended: ${pcName}`, track);
        reNegotiateMainAudio(pc, track, pcName);
    }
    track.onmute = () => console.log(`muted: ${pcName}`);
    if (mainAudioTrackId === undefined && track.kind === 'audio') {
      mainAudioTrackId = track.id;
    }
    pc.addTrack(track, localStream);
  });
  localStream.onremovetrack = () => console.log(`removed track for: ${pcName}`);
  pcMap.get(pc).set("localStream", localStream);
  mainPc = pc;
  // NOTE: communicate device-metadata and/or disconnects/reconnects via this channel
  // => maybe: different channel for _THE DATA_ later on ^^
  deviceDataChannel = pc.createDataChannel("devices");
  deviceDataChannel.onopen = (_event) => {
    // console.log("dataChannel opened");
  };
  deviceDataChannel.onmessage = (event) => {
    console.log("message: ", event.data);
  };
  isOffer &&
    pc
      .createOffer()
      .then((offer) => {
        return pc.setLocalDescription(offer);
      })
      .then(() => {
        broadcastData({
          type: EXCHANGE,
          from: currentUser,
          to: userId,
          sdp: JSON.stringify(pc.localDescription),
          roomName,
        });
      })
      .catch(logError);
  pc.onicecandidate = (event) => {
    event.candidate &&
      broadcastData({
        type: EXCHANGE,
        from: currentUser,
        to: userId,
        candidate: JSON.stringify(event.candidate),
        roomName,
      });
  };
  pc.ontrack = (event) => {
    console.log("ontrack called: " + event.streams[0].id + ", event:");
    console.log(event);
    if (handledTrackIds.has(event.track.id)) {
      return;
    }
    let element;
    pcMap.get(pc).set("localStream", event.streams[0]);
    let streamId = event.streams[0].id;
    if (document.getElementById(`remoteView+${userId}`) == null) {
      element = document.createElement("video");
      document.getElementById("placeholder-video-remote").style.display =
        "none";
      element.id = `remoteView+${userId}`;
      remoteViewStreamId = streamId;
      element.autoplay = "autoplay";
      element.controls = "controls";
      element.srcObject = event.streams[0];
      element.dataset['webrtcRoomTarget'] = 'remoteView';
      if (sinkDeviceId) {
        element.setSinkId(sinkDeviceId);
      }
      handledTrackIds.add(event.track.id);
      remoteViewContainer.appendChild(element);
    } else {
      console.warn("ontrack called for existing (main) stream");
      return;
    }
  };
  pc.onconnectionstatechange = (event) => {
    // TODO: do we have pc or this.pc? or event.pc?
    console.log("cstatechange: " + pc.connectionState);
    switch (pc.connectionState) {
      case "connected":
      case "connecting":
        break;
      case "disconnected":
      case "failed":
      case "closed":
        console.log("closed event", event);
        if (remoteViewContainer !== undefined) {
          remoteViewContainer.className = "mainViewContainer"
        }
        handledDeviceIds.delete(pcMap.get(pc).get("deviceId")); // NOTE/TODO: what if no devId? => also handle main dev ids!
        pc.close();
        for (const [pcName, pcPeerPc] of pcPeers) {
          if (
            pcPeerPc !== undefined &&
            pcPeerPc.connectionState === "closed"
          ) {
            pcPeers.set(pcName, undefined);
            // shouldn't we clear this completely here?
            pcMap.delete(pc);
          }
        }
        break;
      default:
        break;
    }
  };
  pc.oniceconnectionstatechange = (_event) => {
    if (pc.iceConnectionState == "disconnected") {
      console.log("Disconnected:", userId);
      let placeholderVR = document.getElementById("placeholder-video-remote")
      if (placeholderVR) {
        document.getElementById("placeholder-video-remote").style.display = "block";
      }
      if (acceptedId) {
        broadcastData({ type: REMOVE_USER, sessionId: acceptedId, from: userId, roomName });
      }
    }
  };
  return pc;
}

function reNegotiateMainAudio(pc, track, pcName) {
  console.log('reattaching audio');
  let origSender;
  const senderList = mainPc.getSenders();
  senderList.forEach((sender) => {
    if (sender.track === track) {
      console.log('origSender: ', sender);
      origSender = sender;
    }
  });
  localStream.removeTrack(track);
  navigator.mediaDevices
  .getUserMedia(constraints)
  .then((stream) => {
    stream.getAudioTracks().forEach ((newTrack) => {
      if (newTrack.kind === 'audio') {
        mainAudioTrackId = track.id;
      }
      newTrack.onended = (_evt) =>  {
        console.log(`ended: ${pcName}`, newTrack);
        reNegotiateMainAudio(pc, newTrack, pcName);
      }
      localStream.addTrack(newTrack);
      origSender.replaceTrack(newTrack);
    });
  });
}
function addDevice(data) {
  let deviceId = data.deviceId;
  let hasAudio = data.hasAudio;
  let hasVideo = data.hasVideo;
  let userId = data.from;
  let remoteUserId = data.to;
  // we probably still need to do this on the initiating device?
  // if (handledDeviceIds.has(deviceId)) {
  // return;
  // }
  let pcName = remoteUserId + "_" + deviceId;
  if (pcPeers.has(pcName)) {
    console.log('pcName in pcPeers existed(?)')
    // NOTE: assume this is a re-connect for the device, ie. it was disconnected on the remote end in between
    // close stream (?), detach source object from $view, if audio: stop wavesurfer; close pc
    // remove pc from everywhere.
    // then: as going on below
    // remoteDeviceViewContainer.innerHTML = "";
    // createpc: isOffer [send offer back to initiating client after pc new]; remote client gets response and answer-s back(?)
    return;
  }
  createNewDevicePC({
    userId: data.to,
    isOffer: true,
    deviceId: deviceId,
    isAudioOnly: hasAudio && !hasVideo,
  });

  handledDeviceIds.add(deviceId);
  testingDeviceIds.add(deviceId);
  // addDeviceButton(deviceId);
}
function exchangeDevice(data) {
  let deviceId = data.deviceId;
  let hasAudio = data.hasAudio;
  let hasVideo = data.hasVideo;
  let userId = data.from;
  let pc;
  if (remoteUser === undefined && data.from !== currentUser) {
    remoteUser = data.from;
  }
  let pcName = userId + "_" + deviceId;
  if (!pcPeers.has(pcName)) {
    pc = createNewDevicePC({
      userId: data.from,
      isOffer: false,
      isNewDevice: true,
      deviceId: deviceId,
      isAudioOnly: hasAudio && !hasVideo,
    });
  } else {
    pc = pcPeers.get(pcName);
  }
  if (pc === undefined || pc === null) {
    pc = createNewDevicePC({
      userId: data.from,
      isOffer: false,
      isNewDevice: true,
      deviceId: deviceId,
      isAudioOnly: hasAudio && !hasVideo,
    });
  }
  if (data.candidate) {
    pc.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)))
      .then(() => console.log("Ice candidate added to devicePC"))
      .catch(logError);
  }
  if (data.sdp) {
    let sdp = JSON.parse(data.sdp);
    pc.setRemoteDescription(new RTCSessionDescription(sdp))
      .then(() => {
        if (sdp.type === "offer") {
          pc.createAnswer()
            .then((answer) => {
              return pc.setLocalDescription(answer);
            })
            .then(() => {
              broadcastData({
                type: EXCHANGE_DEVICE,
                from: currentUser,
                deviceId: deviceId,
                to: userId,
                isAudioOnly: hasAudio && !hasVideo,
                sdp: JSON.stringify(pc.localDescription),
                roomName,
              });
            });
        }
      })
      .catch(logError);
  }
}
function createNewDevicePC(opts) {
  console.log("create new dev pc with:");
  console.log(opts);
  let userId = opts.userId;
  let isOffer = opts.isOffer;
  let deviceId = opts.deviceId;
  let isAudioOnly = opts.isAudioOnly;
  // if (handledDeviceIds.has(deviceId)) {
  // return;
  // }
  // console.log("newdev: " + deviceId);
  // we are calling it locally – we already know the remote (JOIN_ROOM has happened)
  // NOTE: for later: handle join_room if pc exists => makes reconnecting better
  // if (userId === currentUser) {
  // console.log("createpc called from currentUser");
  // userId = remoteUser;
  // }
  let pc = new RTCPeerConnection(rtcConfig);
  let pcName = userId + "_" + deviceId;
  pcPeers.set(pcName, pc);
  pcMap.set(
    pc,
    new Map([
      ["pcName", pcName],
      ["deviceId", deviceId],
      ["userId", userId],
    ])
  );
  // if (userId === currentUser) {
  if (isOffer) {
    // console.log('newDevPC from self');
    localDeviceStreamMap
      .get(deviceId)
      .getTracks()
      .forEach((track) => {
        pc.addTrack(track, localDeviceStreamMap.get(deviceId));
      });
    pcMap.get(pc).set("localStream", localDeviceStreamMap.get(deviceId));
  }
  if (!isOffer) {
    // when "new device from self via remote back to us "
    // NOTE: the above comment is probably bollocks
    console.log('!isoffer in newDevPC');
    // if (localDeviceStreamMap.get(deviceId)) {
      // console.log('actually !isoffer in newDevPC');
      // localDeviceStreamMap
        // .get(deviceId)
        // .getTracks()
        // .forEach((track) => {
          // pc.addTrack(track, localDeviceStreamMap.get(deviceId));
        // });
    // }
    // pcMap.get(pc).set("localStream", localDeviceStreamMap.get(deviceId));
  } else {
    pc.createOffer()
      .then((offer) => {
        return pc.setLocalDescription(offer);
      })
      .then(() => {
        broadcastData({
          type: EXCHANGE_DEVICE,
          from: currentUser,
          to: userId,
          sdp: JSON.stringify(pc.localDescription),
          deviceId: deviceId,
          isAudioOnly: isAudioOnly,
          roomName,
        });
      })
      .catch(logError);
  }
  // now go and add all zeh on...eventhandlers
  // if (isOffer) {
  // localDeviceStreamMap
  // .get(deviceId)
  // .getTracks()
  // .forEach(function (track) {
  // pc.addTrack(track, localDeviceStreamMap.get(deviceId));
  // });
  // pcMap
  // .get(pc)
  // .set("localStream", localDeviceStreamMap.get(deviceId));
  // }

  pc.onicecandidate = (event) => {
    event.candidate &&
      broadcastData({
        type: EXCHANGE_DEVICE,
        from: currentUser,
        to: userId,
        deviceId: deviceId,
        candidate: JSON.stringify(event.candidate),
        roomName,
      });
  };

  let wasStethoscope;
  pc.ontrack = (event) => {
    console.log("ontrack (dev) called: " + event.streams[0].id + ", event:");
    console.log(event);
    if (handledTrackIds.has(event.track.id)) {
      return;
    }
    let element;
    pcMap.get(pc).set("localStream", event.streams[0]);
    let streamId = event.streams[0].id;

    let specificContainer;
    if (event.track.kind === "audio" && event.streams.length == 1) {
      remoteAudioViewContainer || (remoteAudioViewContainer = document.getElementById('remoteAudioViewContainer'));
      // just in case..
      remoteDeviceViewContainer || (remoteDeviceViewContainer = document.getElementById('remoteDeviceViewContainer'));
      element = document.createElement("audio");
      element.id = `remoteDeviceAudio+${userId}+${streamId}`;
      specificContainer = remoteAudioViewContainer;
      specificContainer.className = "mainViewContainer";
      remoteViewContainer.className = "col col-5 col-lg-3 pd-left-0 miniViewContainer miniViewContainer--displaced"
      localViewContainer.classList.add('localViewContainer--displaced');
      broadcastData({
        type: STETHOSCOPE_NEGOTIATION,
        from: currentUser,
        to: remoteUser,
        status: 'connecting',
        roomName,
      });
    } else if (event.track.kind === "video") {
      remoteDeviceViewContainer || (remoteDeviceViewContainer = document.getElementById('remoteDeviceViewContainer'));
      element = document.createElement("video");
      element.id = `remoteDeviceView+${userId}+${streamId}`;
      element.dataset['webrtcRoomTarget'] = 'remoteDeviceView';
      specificContainer = remoteDeviceViewContainer;
      specificContainer.className = "mainViewContainer";
      remoteViewContainer.className = "col col-5 col-lg-3 pd-left-0 miniViewContainer"
    } else {
      return;
    }

    element.autoplay = "autoplay";
    element.srcObject = event.streams[0];

    if (event.track.kind === "video") {
      element.controls = "controls";
      specificContainer.appendChild(element);
    } else if (
      event.track.kind === "audio" &&
      streamId !== remoteViewStreamId
    ) {
      var curRemoteDeviceContainer = document.createElement("div");
      curRemoteDeviceContainer.id = "remoteDeviceAudioContainer+" + streamId;
      var curRemoteDeviceView = document.createElement("div");
      curRemoteDeviceView.id = "remoteDeviceAudioView+" + streamId;
      curRemoteDeviceContainer.appendChild(curRemoteDeviceView);
      element.dataset['stethoscopeTarget'] = 'stethoscopeAudio';
      if (sinkDeviceId) {
        element.setSinkId(sinkDeviceId);
      }
      curRemoteDeviceContainer.appendChild(element);
      curRemoteDeviceContainer.dataset['controller'] = 'stethoscope';
      curRemoteDeviceContainer.dataset['stethoscopePublicKeysUrlValue'] = `/users/${currentUser}/e2ee_public_keys`;
      // curRemoteDeviceContainer.dataset['stethoscopeStreamingValue'] = false;
      curRemoteDeviceView.dataset['stethoscopeTarget'] = 'stethoscopeDeviceView';

      specificContainer.appendChild(curRemoteDeviceContainer);
      // wavesurfer = WaveSurfer.create({
        // // container: "#remoteDeviceAudioView+" + streamId,
        // container: curRemoteDeviceView,
        // waveColor: "black",
        // interact: false,
        // cursorWidth: 0,
        // plugins: [
          // MicrophonePlugin.create({ stream: event.streams[0] })
        // ]
      // });
      // wavesurfer.microphone.start();
      wasStethoscope = true;
      if (event.track.enabled && event.track.readyState === 'live') {
        broadcastData({
          type: STETHOSCOPE_NEGOTIATION,
          from: currentUser,
          to: remoteUser,
          status: 'connected',
          roomName,
        });
      }
    }
    event.track.onended = (_evt) => {
      broadcastData({
        type: STETHOSCOPE_NEGOTIATION,
        from: currentUser,
        to: remoteUser,
        status: 'disconnected',
        roomName,
      });
    };
    handledTrackIds.add(event.track.id);
  };

  let closeDevPc = (event) => {
    console.log('closedevpc);')
    let streamId;
    if (pcMap.has(pc)) {
      streamId = pcMap.get(pc).get("localStream").id;
    }

    let elementA;
    let elementV;
    let audio1;
    let audio2;

    console.log("closeDev event", event);
    elementA = document.getElementById(
      `remoteDeviceAudio+${remoteUser}+${streamId}`
    );
    audio1 = document.getElementById("remoteDeviceAudioView+" + streamId);
    audio2 = document.getElementById(
      "remoteDeviceAudioContainer+" + streamId
    );
    elementV = document.getElementById(
      `remoteDeviceView+${remoteUser}+${streamId}`
    );
    // if (wavesurfer && (audio1 || audio2 || elementA)) {
      // wavesurfer.destroy();
    // }
    if (audio2) {
      audio2.dataset['stethoscopeStreamingValue'] = false;
      audio2.classList.remove = "mainViewContainer";
      localViewContainer.classList.remove('localViewContainer--displaced');
      remoteViewContainer.className = "mainViewContainer";

      broadcastData({
        type: STETHOSCOPE_NEGOTIATION,
        from: currentUser,
        to: remoteUser,
        status: 'disconnected',
        roomName,
      });
    }
    if (elementV) {
      elementV.parentElement.removeChild(elementV);
      remoteDeviceViewContainer.className = "";
      remoteViewContainer.className = "mainViewContainer";
    }
    if (elementA) {
      elementA.parentElement.removeChild(elementA);
    }
    if (audio1) {
      audio1.parentElement.removeChild(audio1);
    }
    if (audio2) {
      audio2.parentElement.removeChild(audio2);
    }
    handledDeviceIds.delete(pcMap.get(pc).get("deviceId"));
    console.log('closedev: ', deviceId)
    pc.close();
    for (const [pcName, pcPeerPc] of pcPeers) {
      if (
        pcPeerPc !== undefined &&
        pcPeerPc.connectionState === "closed"
      ) {
        pcPeers.set(pcName, undefined);
        pcMap.delete(pcPeerPc);
      }
    }
  }

  pc.onconnectionstatechange = event => {
    console.log("cstatechange: (dev) " + pc.connectionState);

    switch (pc.connectionState) {
      case "connected":
        if (wasStethoscope) {
          broadcastData({
            type: STETHOSCOPE_NEGOTIATION,
            from: currentUser,
            to: remoteUser,
            status: 'connected',
            roomName,
          });
        }
      case "connecting":
        break;
      case "disconnected":
      case "failed":
      case "closed":
        closeDevPc(event);
        break;
      default:
        break;
    }
  };

  pc.onclose = event => closeDevPc(event);

  pc.oniceconnectionstatechange = event => {
    if (pc.iceConnectionState == "disconnected" && pc != mainPc) {
      console.log("Disconnected device:", userId, deviceId);
      broadcastData({ type: REMOVE_DEVICE, from: userId, deviceId: deviceId, roomName });
      closeDevPc(event);
      // NOTE: currently unhandled
    }
  };

  return pc;
}
const exchange = (data) => {
  if (data.sdp) {
    let sdp_log = JSON.parse(data.sdp);
    if (sdp_log.type === "offer" || sdp_log.type === "answer") {
      console.log("exchange with:", data);
    }
  }
  let pc;
  if (remoteUser === undefined && data.from !== currentUser) {
    remoteUser = data.from;
  }
  if (!pcPeers.has(data.from)) {
    pc = createInitialPC({
      userId: data.from,
      isOffer: false,
      isNewDevice: false,
      deviceId: "",
      isAudioOnly: data.isAudioOnly,
    });
  } else {
    pc = pcPeers.get(data.from);
  }
  if (data.candidate) {
    pc.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)))
      .then(() => console.log("Ice candidate added"))
      .catch(logError);
  }
  if (data.sdp) {
    let sdp = JSON.parse(data.sdp);
    pc.setRemoteDescription(new RTCSessionDescription(sdp))
      .then(() => {
        if (sdp.type === "offer") {
          pc.createAnswer()
            .then((answer) => {
              return pc.setLocalDescription(answer);
            })
            .then(() => {
              broadcastData({
                type: EXCHANGE,
                from: currentUser,
                to: data.from,
                isAudioOnly: data.isAudioOnly,
                sdp: JSON.stringify(pc.localDescription),
                roomName,
              });
            });
        }
      })
      .catch(logError);
  }
};
// NOTE: DEVICES
//
//
function updateDeviceList() {
  navigator.mediaDevices.enumerateDevices().then(function (devices) {
    // remove all childs
    let currentIds = devices.map((mediaDevice) => mediaDevice.deviceId);
    let removedIds = Array.from(localDeviceIds).filter(
      (x) => !currentIds.includes(x)
    );
    localDeviceIds = new Set(currentIds);
    if(removedIds.length > 0) {
      deviceCleanup(removedIds);
    }
    devices.forEach((device) => {
      let [_kind, type, direction] = device.kind.match(
        /(\w+)(input|output)/i
      );
      let isStethoscope = false;
      let maybeStethoscope = false;
      let isEndocam = false;
      maybeStethoscope = STETHOSCOPE_LABELS.some((element) => device.label.includes(element));
      const blacklisted = ['communication', 'standard', 'default']
      isStethoscope = maybeStethoscope &&
      blacklisted.every(elem => !device.label?.toLowerCase()?.includes(elem)) &&
      blacklisted.every(elem => !device.deviceId?.toLowerCase()?.includes(elem))

      isEndocam = ENDOCAM_LABELS.some((element) =>
        device.label.includes(element)
      );
      if (type === "audio" && direction === "input") {
        console.log(
          "Audio Device: " + device.label + " id: " + device.deviceId + ' Stethoskop: ' + (isStethoscope ? 'yes' : 'no')
        );
        if (isStethoscope && (localStethoscopes.size === 0)) {
          console.log('calling steth', device.deviceId + '  ' + device.label);
          localStethoscopes.add(device.deviceId);
          addStethoscope(device.deviceId);
          addDeviceButton({type: 'audio', label: device.label, deviceId: device.deviceId});
        }
      } else if (type === "video" && device.deviceId !== "default") {
        console.log(
          "Video Device: " + device.label + " id: " + device.deviceId + ' Endocam: ' + (isEndocam ? 'yes' : 'no')
        );
        if (isEndocam) {
          addEndocam(device.deviceId);
          addDeviceButton({type: 'video', label: device.label, deviceId: device.deviceId});
        }
      } else {
        console.log("Unknown Device Type: " + type + " - label:" + device.label + ' id: ' + device.deviceId + ' direction: ' + direction);
      }
    });
  });
}
function addStethoscope(deviceId) {
  // if (localStethoscopes.size !== 0) return;
  let constr = {
    volume: 1.0, // might not work, seems to be dropped
    autoGainControl: false,
    echoCancellation: false,
    noiseSuppression: false,
  };
  navigator.mediaDevices.getUserMedia({ audio: { deviceId: deviceId } }).then((stream) => {
      console.log('adding steth');
      let track = stream.getAudioTracks()[0];
      localStream.onremovetrack = () => console.log(`removed track for: devstr`);
      track.onmute = () => console.log(`muted: devstr`);
      track.onended = () => console.log(`ended: devstr`);

      localDeviceViewContainer || (localDeviceViewContainer = document.getElementById('localDeviceViewContainer'));
      let stethoscopeStatusButton = document.createElement('span');
      stethoscopeStatusButton.id = `stethoscopeStatusButton+${deviceId}`;
      stethoscopeStatusButton.className = "stethoscope-status"
      let statusIcon = document.createElement('i');
      statusIcon.className = "fas fa-3x fa-stethoscope"
      stethoscopeStatusButton.append(statusIcon);
      localDeviceViewContainer.append(stethoscopeStatusButton);
      localDeviceViewContainer.className = "col col-1 pd-left-0 miniViewContainer"
      track.applyConstraints(constr).then(() => {
        localDeviceStreamMap.set(deviceId, stream);
        addDevice({
          deviceId: deviceId,
          hasAudio: true,
          from: currentUser,
          to: remoteUser,
          hasVideo: false,
          roomName: roomName,
        })
        localDeviceIdsInUse.add(deviceId);
      });
      // localStethoscopes.add(deviceId);
      // localStream.getAudioTracks()[0].enabled = false;
      // handledDeviceIds.add(deviceId);
      testingDeviceIds.add(deviceId);
  });
}
// blah.values().next().value
function addEndocam(deviceId) {
  if (localEndocams.size !== 0) return;
  console.log("addendocam" + deviceId);
  navigator.mediaDevices
    .getUserMedia({
      video: {
        deviceId: deviceId,
      },
    })
  .then((stream) => {
      localDeviceStreamMap.set(deviceId, stream);

      localDeviceViewContainer || (localDeviceViewContainer = document.getElementById('localDeviceViewContainer'));
      let element = document.createElement("video");
      element.id = `localDeviceView+${deviceId}`;
      localDeviceViewContainer.className = "col col-5 col-lg-3 pd-left-0 miniViewContainer"
      element.autoplay = "autoplay";
      element.controls = "controls";
      element.srcObject = stream;
      localDeviceViewContainer.append(element);
      addDevice({
        deviceId: deviceId,
        hasAudio: false,
        from: currentUser,
        to: remoteUser,
        hasVideo: true,
        roomName: roomName,
      })
      localDeviceIdsInUse.add(deviceId);
      localEndocams.add(deviceId);
      // handledDeviceIds.add(deviceId);
      testingDeviceIds.add(deviceId);
  });
}

function addDeviceButton(data) {
  if (deviceSelector  === undefined || deviceSelector  === null) {
    deviceSelector = document.getElementById('deviceSelector');
  }
  const newButton = document.createElement('button');
  newButton.classList.add('btn');
  newButton.classList.add('btn-primary');
  newButton.classList.add('device-disconnect-button');
  newButton.dataset['webrtcRoomTarget'] = 'deviceDisconnectButton';
  newButton.dataset['deviceId'] = data.deviceId;
  newButton.dataset['action'] = 'click->webrtc-room#removeDevice';
  const buttonText = document.createTextNode(`Gerät (${data.type}): ${data.label} trennen.`)
  newButton.append(buttonText);
  deviceSelector.append(newButton);
}

export function removeDeviceButton(deviceId) {
  let deviceButtons = document.querySelectorAll(`button[data-device-id="${deviceId}"]`);
  // console.log(`button[data-deviceId="${deviceId}"]`)
  deviceButtons.forEach(function(deviceButton) {
    deviceButton.remove();
  });
}

navigator.mediaDevices.ondevicechange = () => updateDeviceList();

export function deviceCleanup(deviceIds) {
  console.log('deviceCleanup called with: ', deviceIds)
  deviceIds.forEach((deviceId) => {
    if (!testingDeviceIds.has(deviceId)) return;
    localDeviceStreamMap.delete(deviceId);
    localEndocams.delete(deviceId);
    localStethoscopes.delete(deviceId);
    let localDeviceVideoElement = document.getElementById(`localDeviceView+${deviceId}`)
    let localDeviceVideoStreamTracks = localDeviceVideoElement?.sourceObject?.getTracks();
    if (localDeviceVideoElement) {
      if (localDeviceVideoStreamTracks) {
        for (const track of localDeviceVideoStreamTracks) {
          track.stop();
        }
        localDeviceVideoStreamTracks.sourceObject = undefined;
      }
      localDeviceVideoElement.remove();
    }
    localDeviceViewContainer.className = '';
    let stethoscopeStatusButton = document.querySelector('.stethoscope-status');
    if (stethoscopeStatusButton) {
      stethoscopeStatusButton.remove();
    }
    stethoscopeStatusString = null;
    // if (localStethoscopes.size === 0) {
      // // more unmuting
      // localStream.getAudioTracks()[0].enabled = true;
    // }
    localDeviceIdsInUse.delete(deviceId);
    handledDeviceIds.delete(deviceId);
    testingDeviceIds.delete(deviceId);
    let pcString = remoteUser + "_" + deviceId;
    if (pcPeers.has(pcString)) {
      let pc = pcPeers.get(pcString);
      pc.close()
      pcMap.delete(pc);
    }
    pcPeers.delete(pcString); // NOTE/TODO: check this

    removeDeviceButton(deviceId);
    console.log("Disconnected device (local):", deviceId);
  });
  // deviceDisconnectButton.dataset[deviceId] = '';
  // deviceDisconnectButton.classList.add(d-none);
}
const showGuide = async () => {
  broadcastData({
    type: SHOW_GUIDE,
    from: currentUser,
    guide: "medical-instrument-stethoscope.svg",
    roomName,
  });
};
function displayGuide(data) {
  let guideContainer = document.getElementById("guideContainer");
  guideContainer.innerHTML = "";
  let element = document.createElement("img");
  element.setAttribute("height", "200");
  element.setAttribute("width", "200");
  element.setAttribute("src", asset_path(data.guide));
  guideContainer.appendChild(element);
  $("#anleitung").modal("show");
}
function connectUser(userId) {
  // if (localUserJoined) return;
  if (acceptedId) return;
  proposedId = uuid();
  broadcastData({ type: JOIN_ROOM, from: userId, proposedId: proposedId, roomName });
  // localUserJoined = true;
}

function acceptSessionId(data) {
  acceptedId = data.sessionId;
}

function joinRoom(data) {
  if (acceptedId) return;
  acceptedId = data.proposedId;
  broadcastData({ type: SESSION_ID, from: currentUser, sessionId: acceptedId, roomName })

  // remoteUserJoined = true;
  console.log("join room from " + data.from + "data: ", data);
  remoteUser = data.from;
  createInitialPC({
    userId: data.from,
    isOffer: true,
    isNewDevice: false,
    deviceId: "",
    isAudioOnly: false,
  });
}
function removeUser(data) {
  if (data.sessionId !== undefined && acceptedId !== undefined && acceptedId === data.sessionId)
  {
    console.log("removing user", data.from);
    // let video = document.getElementById(`remoteView+${data.from}`);
    // video && video.remove();
    // pcPeers.delete(data.from);
    handleLeaveSession(true);
  }
}
function logError(error) {
  console.warn("Error:", error);
}
function letter(data) {
  const patientLetterButton = document.getElementById('patientLetterButton');
  if (!patientLetterButton) return;
  let formData = new FormData();
  formData.append('consultation_id', data.consultation);
  fetch('/doctor_letters/link', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: formData,
      credentials: 'same-origin'
    })
    .then(response => {
      response.text().then(responseText => {
        let parentElement = patientLetterButton.parentElement.parentElement;
        parentElement.innerHTML = '';
        parentElement.innerHTML = responseText;
      })
    })
  // if (data.created === "success") {
    // $.ajax({
      // type: "POST",
      // url: "/doctor_letters/link",
      // data: {
        // doctor_letter_id: data.letter,
      // },
      // success(data_re) {
        // console.log(data_re);
        // $(".letter-link").first().html(data_re);
        // return true;
      // },
      // error(_data_re) {
        // return false;
      // },
    // });
  // }
}

function changeAudioDestination(event) {
  let deviceId = event.target.value;
  let outputSelector = event.target;
  let element = document.querySelector("#remoteViewContainer>video");
  attachSinkId(element, deviceId, outputSelector);
  sinkDeviceId = deviceId;
  let elementAudio = document.querySelector(
    "#remoteAudioViewContainer>audio"
  );
  if (elementAudio !== null) {
    attachSinkId(elementAudio, deviceId, outputSelector);
  }
}
// Attach audio output device to the provided media element using the deviceId.
function attachSinkId(element, sinkId, outputSelector) {
  if (typeof element.sinkId !== "undefined") {
    element
      .setSinkId(sinkId)
      .then(() => {
        console.log(
          `Success, audio output device attached: ${sinkId} to element with ${element.title} as source.`
        );
      })
      .catch((error) => {
        let errorMessage = error;
        if (error.name === "SecurityError") {
          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
        }
        console.error(errorMessage);
        // Jump back to first output device in the list as it's the default.
        outputSelector.selectedIndex = 0;
      });
  } else {
    console.warn("Browser does not support output device selection.");
  }
}

const removeDeviceHandler = (data) => {
  let deviceId = data.deviceId;
  let remoteUserId = data.from;

  let pc;
  if (remoteUserId === currentUser) {
  } else {

    let pcName = remoteUserId + "_" + deviceId;
    let streamId;
    if (pcPeers.has(pcName)) {
      if (pcPeers.get(pcName) !== undefined) {
        pc = pcPeers.get(pcName);
      } else {
        pcPeers.delete(pcName);
      }
    }
    if (pc === undefined || pc === null) return;

    if (pcMap.has(pc)) {
      // let pc =
      streamId = pcMap.get(pc).get("localStream")?.id;
    }

    let elementA;
    let elementV;
    let audio1;
    let audio2;

    console.log("closeDev event", event);
    elementA = document.getElementById(
      `remoteDeviceAudio+${remoteUser}+${streamId}`
    );
    audio1 = document.getElementById("remoteDeviceAudioView+" + streamId);
    audio2 = document.getElementById(
      "remoteDeviceAudioContainer+" + streamId
    );
    elementV = document.getElementById(
      `remoteDeviceView+${remoteUser}+${streamId}`
    );
    // if (wavesurfer && (audio1 || audio2 || elementA)) {
      // wavesurfer.destroy();
    // }
    if (audio2) {
      audio2.dataset['stethoscopeStreamingValue'] = false;
    }

    if (elementV) {
      elementV.parentElement.removeChild(elementV);
      remoteDeviceViewContainer.className = "";
      remoteViewContainer.className = "mainViewContainer";
    }
    if (elementA) {
      elementA.parentElement.removeChild(elementA);
    }
    if (audio1) {
      audio1.parentElement.removeChild(audio1);
    }
    if (audio2) {
      audio2.parentElement.removeChild(audio2);
    }
    handledDeviceIds.delete(pcMap.get(pc).get("deviceId"));
    pc.close();
    // pc.close().then(() => {
      for (const [pcName, pcPeerPc] of pcPeers) {
        if (
          pcPeerPc !== undefined &&
          pcPeerPc.connectionState === "closed"
        ) {
          pcPeers.set(pcName, undefined);
          pcMap.delete(pcPeerPc);
        }
      }
    // })
  }
}

const endSession = () => {
  handleLeaveSession(false);
  // localUserJoined = false;
  window.location.replace("https://vs.ihr-arzt-online.de");
};

const refreshForms = (data) => {
  const formData = new FormData();
  formData.append('consultation_id', data.consultation);

  fetch('/consultations/notice', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: formData,
    credentials: 'same-origin'
    })
   .then(response => {
      response.text().then(responseText => {
      document.getElementById('patient_notice_card').innerHTML = responseText;
      })
    })
    .catch((error) => {
      console.error(error);
    });
}

function refreshUploadedFiles(data) {
  const formData = new FormData();
  // formData.append('consultation_id', data.consultationId);
  fetch(`/consultations/${data.consultationId}/uploads`, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: formData,
    credentials: 'same-origin'
  })
  .then(response => response.text())
  .then(html => {
    document.querySelector('#uploads-wrapper').outerHTML = html;
  })
}

function updateStethoscopeStatus(data) {
  const stethoscopeStatusButton = document.querySelector('.stethoscope-status');
  switch (data.status) {
    case 'connecting':
      // avoid setting back to connected if for some reason
      // 'connecting' arrives later
      if (!stethoscopeStatusString.includes('status--connected')) {
        stethoscopeStatusString = "stethoscope-status stethoscope-status--connecting"
      }
      break;
    case 'connected':
      stethoscopeStatusString = "stethoscope-status stethoscope-status--connected"
      break;
    case 'disconnected':
      stethoscopeStatusString = "stethoscope-status stethoscope-status--disconnected"
      break;
    default:
      break;
  }
  // console.log('updsteth+ string: ', stethoscopeStatusString);
  if (stethoscopeStatusButton) {
    // console.log('updsteth+ button')
    stethoscopeStatusButton.className = stethoscopeStatusString;
  }
}


function recordingPermissionRequest(data) {
  const mainRoomContainer = document.getElementById('mainRoomContainer');
  const recordingPermissionValue = mainRoomContainer.dataset['webrtcRoomRecordingsAllowedValue'];
  const consultationId = mainRoomContainer.dataset['roomsConsultationIdValue'];
  if (recordingPermissionValue === 'true' || recordingPermissionValue === '1') {
    return;
    // TODO: or handle it cleaner by re-sending confirm?
  }
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success m-3',
      denyButton: 'btn btn-danger m-3'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons.fire({
    title: 'Aufnahmen erlauben',
    icon: 'question',
    text:
      'Ihr:e Behandler:in möchte zu Dokumentationszwecken ' +
      'Bild- und Tonaufnamen von der Behandlung erstellen. ' +
      'Sind Sie damit einverstanden?',
    focusConfirm: true,
    showDenyButton: true,
    confirmButtonAriaLabel: 'Accept',
    confirmButtonText: 'Einwilligen',
    denyButtonText: 'Nicht einwilligen',
    denyButtonAriaLabel: 'Reject'
  }).then((result) => {
    if (result.isConfirmed) {
      const formData = new FormData();
      fetch(`/consultations/${consultationId}/accept_recordings`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: formData,
        credentials: 'same-origin'
      })
      .then(response => {
        if (response.ok) {
          mainRoomContainer.dataset['webrtcRoomRecordingsAllowedValue'] = 'true';
          broadcastData({
            type: RESPOND_RECORDING_PERMISSION,
            from: currentUser,
            to: remoteUser,
            permisson: 'granted',
            roomName,
          });
        } else {
          broadcastData({
            type: RESPOND_RECORDING_PERMISSION,
            from: currentUser,
            to: remoteUser,
            permisson: 'denied',
            roomName,
          });
        }
      })
    } else if (result.isDismissed || result.isDenied) {
      broadcastData({
        type: RESPOND_RECORDING_PERMISSION,
        from: currentUser,
        to: remoteUser,
        permisson: 'denied',
        roomName,
      });
    }
  })
}

function recordingPermissionResponse(data) {
  const mainRoomContainer = document.getElementById('mainRoomContainer');
  const recordingPermissionValue = mainRoomContainer.dataset['webrtcRoomRecordingsAllowedValue'];
  if (recordingPermissionValue === 'true' || recordingPermissionValue === '1') {
    return;
  }
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  });

  if (data.permisson === 'granted') {
    mainRoomContainer.dataset['webrtcRoomRecordingsAllowedValue'] = 'true';
    swalWithBootstrapButtons.fire({
      title: 'Aufnahmen genehmigt',
      icon: 'success',
      text:
        'Ihr:e Patient:in ist mit dem Erstellen von ' +
        'Bild- und Tonaufnamen der Behandlung einverstanden.',
      focusConfirm: true,
      confirmButtonAriaLabel: 'OK',
    });
  } else {
    swalWithBootstrapButtons.fire({
      title: 'Aufnahmen abgelehnt',
      icon: 'error',
      text:
        'Ihr:e Patient:in ist nicht mit dem Erstellen von ' +
        'Bild- und Tonaufnamen der Behandlung einverstanden.',
      focusConfirm: true,
      confirmButtonAriaLabel: 'OK',
    });
  }
}


// export function setLocalUserJoined(localUserState) {
  // localUserJoined = localUserState;
// }

export function broadcastData(data) {
  const formData = new FormData();
  for (const [k, v] of Object.entries(data)) {
    formData.append(k, v);
  }

  fetch('/sessions', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: formData,
      credentials: 'same-origin'
    })
    .then(response => {
      response.text().then(responseText => {
        console.log(responseText);
      })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

function uuid() {
    var uuid = "", i, random;

    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;

        if (i == 8 || i == 12 || i == 16 || i == 20) {
            uuid += "-";
        }

        uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
     }

     return uuid;
}
