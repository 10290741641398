import { Controller } from "stimulus";
import { basicDigestMessage } from "../model/crypto";
// import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["passwordField", "submitButton", "form"]

  static values = { url: String,
                    method: String }

  generatePasswordHash() {
    event.preventDefault();
    event.stopPropagation();
    const password = this.passwordFieldTarget.value.trim();
    basicDigestMessage(password).then((digestHex) => {
      sessionStorage.setItem("digestHex", digestHex);
    });
  }
}
