import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { spinnerShow, spinnerHide } from "../model/spinner";
import { unlockedKeyPresent, decryptFile, readArmoredPublicKeys } from "../model/crypto";
import * as bootstrap from 'bootstrap';
import WaveSurfer from "../lib/wavesurfer.js/wavesurfer";

export default class extends Controller {

  static targets = ["plaintextField",
                    "wavesurferWrapper",
                    "wavesurferModal",
                    "zoomSlider",
                    "wavesurferElement",
                    "publicKeyId"];

  static values = { publicKeysUrl: String }

  openWaveform(ev) {
    let target = ev.target;
    if (target.tagName.toLowerCase() === 'path') {
      target = target.parentElement;
    } else if (target.tagName.toLowerCase() === 'button') {
      target = target.firstElementChild;
    }

    const fileDataset = target.dataset;
    const fileUrl = fileDataset.uploadsUrlValue;
    const fileContentType = fileDataset.uploadsTypeValue;
    const filename = fileDataset.uploadsFilenameValue;

    fetch (fileUrl, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    })
    .then(response => response.text())
    .then(text => this.decryptUpload(text, filename, fileContentType))
    .then(decryptedFile => {
      this.setupWavesurferModal(decryptedFile);
    })
  }

  async setupWavesurferModal(decryptedFile) {
    this.fetchWavesurferModal(decryptedFile);
    // .then( () => {
      // this.setupWavesurfer(decryptedFile);
    // });
  }

  async fetchWavesurferModal(decryptedFile) {
    const consultationId = document.querySelector("[data-consultation-id]").dataset['consultationId'];
    fetch(`/consultations/${consultationId}/uploaded-wave-modal`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(response => {
      response.text().then(responseText => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(responseText, 'text/html');
        this.wavesurferWrapperTarget.append(doc.querySelector('div'));
        this.setupWavesurfer(decryptedFile);
      })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
  setupWavesurfer(decryptedFile) {
    const wavesurferElement = this.wavesurferElementTarget;
    this.wavesurfer = WaveSurfer.create({
      container: wavesurferElement,
      waveColor: "black",
      height: 512,
      forceDecode: true,
      responsive: true,
      scrollParent: true,
    });
    this.wavesurfer.loadBlob(decryptedFile);
    this.showWavesurferModal();
  }

  showWavesurferModal() {
    // TODO: mute sStream if present??
    const wavesurferModalElement = this.wavesurferModalTarget;
    const wavesurferModal = new bootstrap.Modal(wavesurferModalElement);
    wavesurferModal.show();
  }

  wavesurferModalShown() {
    const slider = this.zoomSliderTarget;
    slider.value = this.wavesurfer.params.minPxPerSec;
    slider.min = this.wavesurfer.params.minPxPerSec;
    slider.max = 1000;
    // set initial zoom to match slider value
    this.wavesurfer.zoom(slider.value);
  }

  zoom() {
    this.wavesurfer.zoom(Number(this.zoomSliderTarget.value));
  }

  play() {
    // console.log('play')
    this.wavesurfer.playPause();
  }

  dismissWavesurferModal() {
    const wavesurferModalElement = this.wavesurferModalTarget;
    const wavesurferModal = bootstrap.Modal.getInstance(wavesurferModalElement);
    this.wavesurfer.stop();
    wavesurferModal.hide();
    this.wavesurfer.destroy();
    this.wavesurferModalTarget.remove();
  }

  wavesurferModalDismissed() {
    if (this.wavesurfer) this.wavesurfer.destroy();
    if (this.wavesurferModalTarget) this.wavesurferModalTarget.remove();
  }

  async downloadFile(ev) {
    let target;
    if (ev.target.childElementCount === 0) {
      target = ev.target;
    } else {
      target = ev.target.firstElementChild;
    }

    const fileDataset = target.dataset;
    const fileUrl = fileDataset.uploadsUrlValue;
    const fileContentType = fileDataset.uploadsTypeValue;
    const filename = fileDataset.uploadsFilenameValue;
    // console.log(fileUrl, fileContentType);

    fetch (fileUrl, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    })
    // .then(response => response.blob()) // raw/array/stream seems to make issues during enc.
    .then(response => response.text())
    .then(text => this.decryptUpload(text, filename, fileContentType))
    .then(decryptedFile => {
      var newA = document.createElement('a'),
        url = URL.createObjectURL(decryptedFile);

      newA.onload = () => {
        URL.revokeObjectURL(url);
      };

      newA.href = url;
      newA.download = decryptedFile.name;

      newA.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  async decryptUpload(blob, filename, fileType) {
    this.element.dataset.cryptInProgress = true;
    spinnerShow();
    const decryptedBlob = await decryptFile(blob, filename, fileType);
    this.element.dataset.cryptInProgress = false;
    spinnerHide();
    return decryptedBlob;
  }

  async getPublicKeys(publicKeyIds) {
    const response = await fetch(this.publicKeysUrlValue, {
      method: 'post',
      body: JSON.stringify(publicKeyIds),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });

    return response.json();
  }
}
