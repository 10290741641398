import { Controller } from "stimulus";
import Dropzone from "dropzone";
import Rails from "@rails/ujs";
import { spinnerShow, spinnerHide } from "../model/spinner";
import { unlockedKeyPresent, encryptFile, readArmoredPublicKeys } from "../model/crypto";

export default class extends Controller {

  static targets = ["ciphertextField",
                    "plaintextField",
                    "publicKeyId",
                    "dropzonePreviewsContainer",
                    "dropzonePreviewsTemplate",
                    "uploadButton",
                    "input"];

  static values = { currentUserId: String,
                    roomName: String,
                    publicKeysUrl: String,
                    uploadedFilesUrl: String,
                    consultationId: String }

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    if (this.dropZone.files.length === 0) {
      this.uploadButtonTarget.setAttribute("disabled", "disabled");
    }
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  async dropzoneEncryptFile(file) {
    this.element.dataset.cryptInProgress = true;
    spinnerShow();
    this.file = file;
    const publicKeyIdTargets = this.publicKeyIdTargets;
    const publicKeyIds = publicKeyIdTargets.map( function(publicKeyId) { return publicKeyId.textContent.trim(); } );
    this.uploadButtonTarget.setAttribute("disabled", "disabled");
    const publicKeys = await this.getPublicKeys(publicKeyIds);
    const publicKeyObjs = await readArmoredPublicKeys(publicKeys);
    const publicKeyObjsResolved = await Promise.all(publicKeyObjs);
    const plaintextMessage = file;

    let encrypted = await encryptFile(plaintextMessage, publicKeyObjsResolved)

    this.element.dataset.cryptInProgress = false;
    spinnerHide();
    return encrypted;
  }

  async getPublicKeys(publicKeyIds) {
    const response = await fetch(this.publicKeysUrlValue, {
      method: 'post',
      body: JSON.stringify(publicKeyIds),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    });

    return response.json();
  }

  bindEvents() {
    // this.dropZone.on("canceled", file => {
      // file.controller && file.controller.xhr.abort();
    // });

  this.dropZone.on('complete', file => {
    this.dropZone.removeFile(file);
    // this.uploadButtonTarget.removeAttribute('disabled');
  });

  this.dropZone.on('removedfile', file => {
    this.uploadButtonTarget.setAttribute("disabled", "disabled");
  });

  this.dropZone.on('addedfile', file => {
    this.uploadButtonTarget.removeAttribute('disabled');
  });

  this.dropZone.on('success', _response => {
    this.broadcastData({
      type: 'REFRESH_UPLOADS',
      from: this.currentUserIdValue,
      roomName: this.roomNameValue,
      consultationId: this.consultationIdValue,
    });
    // TODO/NOTE: use response json to add only one file.
    // instead, let's just re-fetch the partial for now
    // response.json()
    // .then(json => {
    // })
    //
    // NOTE: refresh_uploads is now "for all"
    // const formData = new FormData;
    // // formData.append('consultation_id', this.consultationUserIdValue);
    // fetch(this.uploadedFilesUrlValue, {
      // method: 'POST',
      // headers: {
        // 'X-CSRF-Token': Rails.csrfToken()
      // },
      // body: formData,
      // credentials: 'same-origin'
    // })
    // .then(response => response.text())
    // .then(html => {
      // document.querySelector('#uploads-wrapper').outerHTML = html;
    // })
  });
}

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get dropzonePreviewsContainer() {
    return this.dropzonePreviewsContainerTarget;
  }

  get uploadButton() {
    return this.uploadButtonTarget;
  }

  get dropzonePreviewsTemplate() {
    return this.dropzonePreviewsTemplateTarget;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 5;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  // get addRemoveLinks() {
    // return this.data.get("addRemoveLinks") || true;
  // }

  async broadcastData(data) {
    let formData = new FormData();
    for (const [k, v] of Object.entries(data)) {
      formData.append(k, v);
    }

    fetch('/sessions', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: formData,
        credentials: 'same-origin'
    })
    .then(response => {
      response.text().then(responseText => {
        console.log(responseText);
      })
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
}

function createDropZone(controller) {
  let previewNode = controller.dropzonePreviewsTemplate;
  let previewTemplate = previewNode.parentNode.innerHTML;
  previewNode.parentNode.removeChild(previewNode);


  let dropzone =  new Dropzone(controller.element, {
    headers: { "X-CSRF-Token": Rails.csrfToken() },
    maxFiles: controller.maxFiles,
    thumbnailWidth: 80,
    thumbnailHeight: 80,
    // uploadMultiple: true,
    maxFilesize: controller.maxFileSize,
    paramName: 'consultation[consultation_upload]',
    acceptedFiles: controller.acceptedFiles,
    previewsContainer: controller.dropzonePreviewsContainer,
    previewTemplate: previewTemplate,
    autoProcessQueue: false,
    addRemoveLinks: false,
    transformFile: (file, done) => {
      controller.dropzoneEncryptFile(file)
      .then( (encryptedFile) => {
        done(encryptedFile);
      })
    }
  });

  dropzone.element.querySelector("input[type=submit]").addEventListener("click", function(e) {
    // Make sure that the form isn't actually being sent.
    e.preventDefault();
    e.stopPropagation();
    dropzone.processQueue();
  });

  // dropzone.on("sending", async function(file) {
    // console.log('sending', file)
    // Gets triggered when the form is actually being sent.
    // Hide the success button or the complete form.
    // document.querySelector("#total-progress").style.opacity = "1";
    // file.previewElement.document.querySelector("[data-dropzone-target=uploadButton]");
  // });
  // dropzone.on("success", function(files, response) {
    // // Gets triggered when the files have successfully been sent.
    // // Redirect user or notify of success.
  // });
  // dropzone.on("error", function(files, response) {
    // // Gets triggered when there was an error sending the files.
    // // Maybe show form again, and notify user of error
  // });
  return dropzone;
}
