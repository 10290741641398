import Turbolinks from "turbolinks"

export function turbolinksReload() {
  var scrollPosition

  document.addEventListener('turbolinks:load', function () {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition)
      scrollPosition = null
    }
  }, false)

  scrollPosition = [window.scrollX, window.scrollY]
  // Turbolinks.visit(window.location, { action: 'replace' })
  // Turbolinks.visit(window.location, { flush: true, cacheRequest: false })
  Turbolinks.visit(window.location, { flush: true, cacheRequest: false })
}
