import { Controller } from "stimulus";
// import { generateKey, registerKey } from "../model/crypto";
import { unlockedKeyPresent, decryptText } from "../model/crypto";
import { spinnerShow, spinnerHide } from "../model/spinner";
import { BroadcastChannel } from 'broadcast-channel';

export default class extends Controller {
  static targets = ["ciphertext", "plaintext"];

  static values = { decryptionError: String,
                    checkBroadcastChannel: Boolean }

  async connect() {
    const ciphertextTargets = this.ciphertextTargets;
    const plaintextTargets = this.plaintextTargets;
    const decryptionError = this.decryptionErrorValue;
    this.element.dataset.cryptInProgress = true;
    spinnerShow();
    if (this.hasCheckBroadcastChannelValue && this.checkBroadcastChannelValue) {
      await this.credsFromBroadcastChannel();
    }

    if (await unlockedKeyPresent()) {
      ciphertextTargets.forEach( function(cipherTarget, idx) {
        const armoredMessage = cipherTarget.textContent;
        try {
          decryptText(armoredMessage).then( function(decrypted) {
          if (cipherTarget.classList.contains('encrypted-date-string')) {
            const decrypted_date = new Date(Date.parse(decrypted))
            plaintextTargets[idx].textContent = decrypted_date.toLocaleDateString('de-DE');
          } else {
            plaintextTargets[idx].textContent = decrypted;
          }
          });
        } catch (_pgpErr) {
            plaintextTargets[idx].textContent = decryptionError;
        }
      })
    }
    this.element.dataset.cryptInProgress = false;
    spinnerHide();
  }

   async credsFromBroadcastChannel() {
    const keyChannel = new BroadcastChannel('tabExchange');
    let msgAnswered = false;
    keyChannel.onmessage = msg => {
      if (msg.answer) {
        sessionStorage.setItem("pubKey", msg.pubKey);
        sessionStorage.setItem("privKey", msg.privKey);
        sessionStorage.setItem("passphrase", msg.passphrase);
        msgAnswered = true;
      }
    };
    keyChannel.postMessage({ request: true })

    let iterations = 0;
    let sessionStorageInterval = setInterval(function() {
      if (iterations++ >= 9) clearInterval(sessionStorageInterval);
      if (sessionStorage.getItem("passphrase") === null) return;
      clearInterval(sessionStorageInterval);
    }, 50);
    return msgAnswered;
    }
}
