import { Controller } from "stimulus";
import { unlockedKeyPresent } from "../model/crypto";
import { BroadcastChannel } from 'broadcast-channel';

export default class extends Controller {
  async connect() {
    const keyChannel = new BroadcastChannel('tabExchange');
    keyChannel.onmessage = async msg => {
      if (msg.request) {
        console.log(msg)
        if (await unlockedKeyPresent()) {
          keyChannel.postMessage({ answer: true,
                                   pubKey: sessionStorage.getItem("pubKey"),
                                   privKey: sessionStorage.getItem("privKey"),
                                   passphrase: sessionStorage.getItem("passphrase")
                                });
        }
      }
    };
  }
}
